import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { motion } from 'framer-motion'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

import ProductShortcut from './../ProductShortcut'

const StyledVideo = styled(motion.div)`
    position: absolute;

    top: 0;
    left: 0;
    
    width: 100%;
    height: 100%;
    
    z-index: 0;
    
    pointer-events: none;
    
    overflow: hidden;

    iframe {
        width: 100% !important;
        height: 56.34vh !important; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 110%;
        min-width: 177.5vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    ${props => props.overlayAmount && `
        &:after {
            content: '';
            position: absolute;

            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            background-color: rgba(0, 0, 0, ${props.overlayAmount / 10});
        }   
    `}
`

const StyledImage = styled(Img)`
    position: absolute !important;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;

    transition: opacity 1s ${props => props.theme.transitions.cubic};

    picture {
        img {
            opacity: 1 !important;
        }
    }
`

const StyledProductShortcut = styled(ProductShortcut)`
    bottom: 1.5rem;
    right: 1.5rem;

    ${props => props.theme.above.desktop`
        bottom: 3rem;
        right: 3rem;
    `}
`

const EmbedVideo = ({
    data,
    type,
    lang,
    animation,
    delay,
    product,
    className
}) => {

    const [source, setSource] = useState(data.medium[0])
    const [videoReady, setVideoReady] = useState(false)
    const [videoPlaying, setVideoPlaying] = useState(false)

    useEffect(() => {
        const getBannerSource = width => {
            if (width >= 800) return data.large[0]
            if (width >= 434) return data.medium[0]
            return data.small[0]
        }

        setSource(type == 'banner' ? getBannerSource(typeof window != 'undefined' ? window.innerWidth : 1000) : data.medium[0])
    }, [])

    const overlayFallback = type == 'banner' ? 0.2 : 0

    return (
        <StyledVideo 
            className={className}
            type={type}
            overlayAmount={overlayFallback}
            animate={animation}
            initial='before'
            variants={{
                before: {
                    scale: 1.1,
                    opacity: 0
                },
                after: {
                    opacity: 1,
                    scale: 1
                }
            }}
            transition={{
                duration: 0.55,
                delay: delay || 0,
                ease: 'circOut'
            }}
        >
            {type != 'banner' && <ReactPlayer
                url={data.video_link}
                width='100%'
                height='100%'
                playing={true}
                onPlay={() => setVideoReady(true)}
                muted={true}
                playsinline={true}
            />}
            {type == 'banner' ? (
                <StyledImage
                    fluid={source.placeholder.fluid}
                    alt={source.placeholder.alt}
                />
            ) : (
                <StyledImage
                    style={{ 
                        opacity: videoReady ? 0 : 1 
                    }}
                    fluid={source.placeholder.fluid}
                    alt={source.placeholder.alt}
                />
            )}
            {product &&
                <StyledProductShortcut
                    lang={lang}
                    product={product}
                />
            }
        </StyledVideo>
    )
}

export default EmbedVideo