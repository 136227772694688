import React from "react"
import styled from "styled-components"

import ArrowSVG from "./../graphics/button-arrow.svg"

const Field = styled.div`
    position: relative;

    width: 100%;
`

const Input = styled.input`
    display: block;

    width: 100%;

    padding: 1.5rem 5rem 1.5rem 1.5rem;

    background-color: rgba(255, 255, 255, 1);
    border: 1px solid #ebebeb;
    border-radius: 0;
    outline: none;

    appearance: none;

    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 1.4rem;

    color: ${props => props.theme.colors.dark};

    &::placeholder {
        color: rgba(0, 0, 0, 0.25);
    }

    ${props => props.theme.above.desktop`
        padding: 2rem 5rem 2rem 2rem;
    `}
`

const Button = styled.button`
    position: absolute;

    top: 0;
    right: 0;

    height: 100%;
    width: 5rem;

    appearance: none;
`

const StyledArrowIcon = styled(ArrowSVG)`
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%) rotate(-90deg);

    width: 1.2rem;
    height: 1.8rem;

    path {
        fill: black;
    }
`

const Form = ({ className }) => {
    return (
        <form
            action="https://alexschoemaker.us7.list-manage.com/subscribe/post?u=c62bc33bffb0575790bebd234&amp;id=7663b04eda"
            method="post"
            name="mc-embedded-subscribe-form"
            target="_blank"
            noValidate
            className={className}
        >
            <Field>
                <Input
                    type="email"
                    name="EMAIL"
                    className="required email"
                    id="mce-EMAIL"
                    placeholder="Email"
                />
                <Button
                    type="submit"
                    value="Aanmelden"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="button"
                >
                    <StyledArrowIcon />
                </Button>
            </Field>

            {/* <div id='mce-responses' className='clear'>
                <div className='response' id='mce-error-response' style={{ display: 'none' }}></div>
                <div className='response' id='mce-success-response' style={{ display: 'none' }}></div>
            </div> */}

            <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
            >
                <input
                    type="text"
                    name="b_c62bc33bffb0575790bebd234_7663b04eda"
                    tabIndex="-1"
                    defaultValue=""
                />
            </div>
        </form>
    )
}

export default Form
