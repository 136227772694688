import React, { useState } from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { animateScroll as scroll } from "react-scroll"

import { generatePath } from "./../../utils/helpers"

import ShortNavigation from "./../Navigation/ShortNavigation"
import ButtonCircle from "./../Buttons/ButtonCircle"
import LanguageSwitch from "../LanguageSwitch"

const StyledHeader = styled(motion.header)`
    position: fixed;

    transform: translateY(0);
    transition: transform 0.3s ${props => props.theme.transitions.cubic};

    z-index: 4;

    width: 100%;

    ${props =>
        props.hiddenStyle == "hidden" &&
        `
        transform: translateY(-110%);
    `}
`

const MobileHeader = styled(motion.div)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    position: relative;

    width: 100%;
    height: 5rem;

    background-color: ${props => props.theme.colors.darkMedium};
    box-shadow: 0.1rem 0.1rem 2rem rgba(0, 0, 0, 0.25);

    ${props => props.theme.above.desktop`
        display: none;
    `}
`

const DesktopHeader = styled.div`
    display: none;

    ${props => props.theme.above.desktop`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        position: relative;

        top: 4rem;
        
        width: 100%;
        // max-width: ${props => props.theme.breakpoints.extraWide}px;

        margin: 0 auto;
        padding: 0 5rem 0 6rem;
    `}
`

const StyledButtonMenu = styled(ButtonCircle)`
    background-color: ${props => props.theme.colors.white};

    .line {
        stroke: ${props => props.theme.colors.dark};
    }

    .circle-icon {
        display: none;
    }
`

const LogoWrapperStyles = css`
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    overflow: hidden;

    color: ${props => props.theme.colors.white};

    ${props => props.theme.above.desktop`
        position: relative;

        top: 0;
        left: 0;

        transform: none;
    `}
`

const LogoWrapper = styled.a`
    ${LogoWrapperStyles}
`

const LogoScrollWrapper = styled.button`
    ${LogoWrapperStyles}

    cursor: pointer;
`

const Logo = styled(motion.div)`
    pointer-events: none;

    line-height: 1;

    svg {
        width: 100%;
        max-width: 20rem;
    }

    path {
        fill: currentColor;
    }

    ${props => props.theme.above.desktop`
        svg {
            width: 20rem;
            height: 1.5rem;
        }
    `}
`

const LanguageWrapper = styled.div`
    width: 5rem;
    height: 5rem;

    background-color: ${props => props.theme.colors.darkLight};

    ${props => props.theme.above.desktop`
        width: auto;
        height: auto;

        background-color: transparent;
    `}
`

const StyledButtonCircle = styled(ButtonCircle)`
    display: inline-block;
    vertical-align: middle;
`

const Header = ({
    lang,
    location,
    menuOpen,
    setMenuOpen,
    shortNavVisible,
    prismicTheme,
}) => {
    const {
        data: { logo, short_nav },
    } = prismicTheme

    return (
        <StyledHeader>
            <MobileHeader
                initial="before"
                animate="after"
                variants={{
                    before: {
                        y: -100,
                    },
                    after: {
                        y: 0,
                    },
                }}
                transition={{
                    duration: 0.35,
                    damping: 100,
                    stiffness: 100,
                    ease: "circOut",
                }}
            >
                <StyledButtonMenu
                    type="menu"
                    animation="after"
                    menuOpen={menuOpen}
                    onClick={() => setMenuOpen(!menuOpen)}
                />
                <LogoWrapper
                    href={`https://livingcreations.com/${
                        lang != "en" ? lang : ""
                    }`}
                    onClick={() => menuOpen && setMenuOpen(!menuOpen)}
                >
                    <Logo
                        dangerouslySetInnerHTML={{ __html: logo.raw[0].text }}
                    />
                </LogoWrapper>
                <LanguageWrapper>
                    <LanguageSwitch mobile={true} />
                </LanguageWrapper>
            </MobileHeader>
            <DesktopHeader>
                {location.pathname == "/" ? (
                    <LogoScrollWrapper onClick={() => scroll.scrollToTop()}>
                        <Logo
                            dangerouslySetInnerHTML={{
                                __html: logo.raw[0].text,
                            }}
                            initial="before"
                            animate="after"
                            variants={{
                                before: {
                                    y: -100,
                                    skewY: -20,
                                },
                                after: {
                                    y: 0,
                                    skewY: 0,
                                },
                            }}
                            transition={{
                                duration: 0.55,
                                damping: 100,
                                stiffness: 100,
                                ease: "circOut",
                            }}
                        />
                    </LogoScrollWrapper>
                ) : (
                    <LogoWrapper href="https://livingcreations.com/">
                        <Logo
                            dangerouslySetInnerHTML={{
                                __html: logo.raw[0].text,
                            }}
                            initial="before"
                            animate="after"
                            variants={{
                                before: {
                                    y: -100,
                                    skewY: -20,
                                },
                                after: {
                                    y: 0,
                                    skewY: 0,
                                },
                            }}
                            transition={{
                                duration: 0.55,
                                damping: 100,
                                stiffness: 100,
                                ease: "circOut",
                            }}
                        />
                    </LogoWrapper>
                )}
                <ShortNavigation
                    lang={lang}
                    data={short_nav}
                    visible={!menuOpen && shortNavVisible}
                />
                <div>
                    {/* <StyledLanguageSwitch
                        lang={lang}
                        menuOpen={menuOpen}
                    /> */}
                    <StyledButtonCircle
                        type="menu"
                        menuOpen={menuOpen}
                        onClick={() => setMenuOpen(!menuOpen)}
                    />
                </div>
            </DesktopHeader>
        </StyledHeader>
    )
}

export default Header
