import React from 'react'
import styled from 'styled-components'

const Button = styled.div`
    padding: 1vw 3vw;

    border-radius: 10rem;
    border: none;

    background-color: rgba(27, 25, 26, 0.65);

    white-space: nowrap;

    ${props => props.theme.above.desktop`
        padding: 0.55vw 1vw 0.5vw 1vw;
    `}
`

const Label = styled.span`
    font-family: ${props => props.theme.fontFamily.neueMontrealRegular};
    font-size: 1.8vw;

    color: ${props => props.theme.colors.white};

    ${props => props.theme.above.desktop`
        font-size: 1.5rem;
    `}
`

const ButtonRounded = ({
    className,
    label
}) => {
    return (
        <Button className={className}>
            <Label className='label'>{label}</Label>
        </Button>
    )
}

export default ButtonRounded
