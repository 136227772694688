import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { generatePath } from '../../utils/helpers'

import ButtonRounded from './../Buttons/ButtonRounded'

const StyledItem = styled.div`
    position: relative;

    width: 100%;
`

const StyledLink = styled(Link)`
    display: block;
`

const StyledImage = styled(Img)`
    margin-bottom: 2rem;

    picture {
        img {
            opacity: 1 !important;
        }
    }
    
    ${props => props.theme.above.desktop`
        margin-bottom: 3rem;
    `}
`

const Header = styled.div`
    margin-bottom: 1rem;
    padding: 0 1rem;

    text-align: center;
`

const Title = styled.h4`
    font-size: 1.4rem;
    line-height: 1.8rem;

    ${props => props.theme.above.desktop`
        font-size: 3rem;
        line-height: 3.5rem;
    `}
`

const Excerpt = styled.span`
    display: block;

    font-size: 1.1rem;
    line-height: 1.5rem;

    ${props => props.theme.above.desktop`
        font-size: 1.6rem;
        line-height: 2rem;
    `}
`

const Item = ({
    lang,
    uid,
    className,
    data: {
        about_image,
        about_title,
        about_excerpt
    }
}) => {
    return (
        <StyledItem className={className}>
            <StyledLink to={generatePath(lang, `about/${uid}`)}>
                <StyledImage 
                    fluid={about_image[0].image.fluid} 
                    alt={about_image[0].image.alt}
                    fadeIn={false} 
                    loading='eager'
                />
                <Header>
                    <Title>{about_title.text}</Title>
                    <Excerpt>{about_excerpt.text}</Excerpt>
                </Header>
            </StyledLink>
        </StyledItem>
    )
}

export default Item
