import React, { useContext } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { DictionaryContext } from './../../contexts/dictionary'

const Wrapper = styled.div`
    width: 100%;
`

const Title = styled(motion.h4)`
    margin-bottom: 2rem;

    font-family: ${props => props.theme.fontFamily.circularMedium};
    font-size: 1.5rem;
    line-height: 1.5rem;

    color: ${props => props.theme.colors.white};
`

const List = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    width: 100%;
    max-width: 45rem;
`

const Item = styled.li`
    width: calc(100% / ${props => props.items});

    text-align: center;
`

const Color = styled(motion.span)`
    display: block;

    width: 100%;
    height: 1.5rem;

    margin-bottom: 1rem;
    
    background-color: ${props => props.color};

    transform-origin: top;
`

const Month = styled.span`
    display: block;

    font-family: ${props => props.theme.fontFamily.neueMontrealRegular};
    font-size: 1.2rem;

    color: ${props => props.theme.colors.darkText};
`

const transition = {
    duration: 0.5,
    damping: 100,
    stiffness: 100,
    ease: 'circOut'
}

const Colors = ({
    data,
    type,
    className,
    animation
}) => {

    const dictionary = useContext(DictionaryContext)

    return (
        <Wrapper className={className}>
            <Title 
                className='title'
                animate={animation}
                variants={{
                    before: {
                        opacity: 0,
                        y: -25
                    },
                    after: {
                        opacity: 1,
                        y: 0
                    }
                }}
                transition={transition}
            >{dictionary.seasonal_coloring}</Title>
            <List className='list'>
                {data.map(({ color, month }, index) => {
                    return (
                        <Item key={index} items={data.length}>
                            <Color
                                className='color'
                                color={type == 'footer' && color.includes('#fff') ? '#f7f1de96' : color}
                                animate={animation}
                                variants={{
                                    before: {
                                        opacity: 0,
                                        scaleY: 0
                                    },
                                    after: {
                                        opacity: 1,
                                        scaleY: 1
                                    }
                                }}
                                transition={transition}
                            />
                            {month && (
                                <Month
                                    className='month'
                                    animate={animation}
                                    variants={{
                                        before: {
                                            opacity: 0
                                        },
                                        after: {
                                            opacity: 1
                                        }
                                    }}
                                    transition={transition}
                                >{dictionary[month.toLowerCase()]}</Month>
                            )}
                        </Item>
                    )
                })}
            </List>
        </Wrapper>
    )
}

export default Colors
