import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"

import { generatePath } from "../../utils/helpers"

const List = styled(motion.ul)`
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(5, 1fr);
    grid-gap: 0 2rem;

    padding: 3rem;

    ${props => props.theme.above.desktop`
        grid-gap: 0 10rem;
        padding: 0;
    `}
`

const Item = styled(motion.li)`
    display: block;

    margin-bottom: 9vw;

    overflow: hidden;

    &:last-of-type {
        margin-bottom: 0;
    }

    ${props => props.theme.above.desktop`
        margin-bottom: 5rem;
        padding: 0;
    `}
`

const Inner = styled(motion.div)``

const StyledLink = styled(Link)`
    display: inline-block;
    position: relative;

    opacity: 0.3;

    transition: opacity 2s ${props => props.theme.transitions.cubic};

    &.active,
    &:hover {
        opacity: 1;
    }

    ${props => props.theme.above.desktop`
        // ${props.theme.underline(props.theme.colors.dark, "0.2rem")}

        &:after {
            margin-top: 0.3rem;
        }
    `}
`

const StyledExternal = styled.a`
    display: inline-block;
    position: relative;

    opacity: 0.3;

    color: ${props => props.theme.colors.dark};

    transition: opacity 2s ${props => props.theme.transitions.cubic};

    &:hover {
        opacity: 1;
    }

    ${props => props.theme.above.desktop`
        // ${props.theme.underline(props.theme.colors.dark, "0.2rem")}

        &:after {
            margin-top: 0.3rem;
        }
    `}
`

const Label = styled.span`
    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 4vw;
    line-height: 4.5vw;
    letter-spacing: 0;

    color: ${props => props.theme.colors.dark};

    ${props => props.theme.above.desktop`
        font-size: 2.5rem;
        line-height: 2.5rem;
    `}
`

const menuVariants = {
    closed: {
        transition: {
            delayChildren: 0.35,
            staggerChildren: 0,
        },
    },
    open: {
        transition: {
            staggerChildren: 0.1,
        },
    },
}

const itemVariants = {
    closed: {
        y: "100%",
        opacity: 0,
    },
    open: {
        y: 0,
        opacity: 1,
    },
}

const Navigation = ({ lang, data, className, menuOpen, setMenuOpen }) => {
    const getLink = (label, link) => {
        if (link.url) {
            return (
                <StyledExternal
                    href={link.url}
                    target="_blank"
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    <Label>{label.text}</Label>
                </StyledExternal>
            )
        } else {
            if (link.uid == "home") {
                return (
                    <StyledExternal
                        href={`https://livingcreations.com/${
                            lang != "en" ? lang : ""
                        }`}
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <Label>{label.text}</Label>
                    </StyledExternal>
                )
            } else {
                return (
                    <StyledLink
                        to={generatePath(lang, link.uid)}
                        onClick={() => setMenuOpen(!menuOpen)}
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        <Label>{label.text}</Label>
                    </StyledLink>
                )
            }
        }
    }

    return (
        <List className={className} variants={menuVariants}>
            {data.map(({ primary }, index) => {
                const { label, link } = primary
                return (
                    <Item key={index}>
                        <Inner
                            variants={itemVariants}
                            initial="closed"
                            transition={{
                                duration: 0.35,
                                damping: 100,
                                stiffness: 100,
                                ease: "circOut",
                            }}
                        >
                            {getLink(label, link)}
                        </Inner>
                    </Item>
                )
            })}
        </List>
    )
}

export default Navigation
