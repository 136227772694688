import React, { useState, useEffect } from "react"
import { motion, useCycle, AnimatePresence } from "framer-motion"

import GlobalStyle from "./GlobalStyle"
import Menu from "./../Navigation/Menu"
import Header from "./Header"
import Footer from "./Footer"
import Preloader from "./Preloader"
import Canvas from "./Canvas"

import { PreloaderContext } from "./../../contexts/preloader"
import { LangProvider } from "./../../contexts/LangContext"

const duration = 0.35
const variants = {
    initial: {
        opacity: 0,
    },
    enter: {
        opacity: 1,
        transition: {
            duration: duration,
            ease: "circOut",
            damping: 100,
            stiffness: 100,
            when: "beforeChildren",
        },
    },
    exit: {
        opacity: 0,
        transition: {
            duration: duration,
            ease: "circOut",
            damping: 100,
            stiffness: 100,
        },
    },
}

const Layout = ({
    children,
    location,
    lang,
    langSlug,
    prismicTheme,
    prismicFooter,
    prismicColors,
}) => {
    const [menuOpen, setMenuOpen] = useCycle(false, true)
    const [showPreloader, setShowPreloader] = useState(
        typeof window != "undefined" && window.innerWidth < 1023 ? false : true
    )
    // const [showPreloader, setShowPreloader] = useState(false)
    const [shortNavVisible, setShortNavVisible] = useState(true)

    const { NODE_ENV } = process.env

    const handleScroll = () => {
        setShortNavVisible(window.scrollY <= 0 ? true : false)
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", () => handleScroll)
        }
    }, [])

    return (
        <PreloaderContext.Provider
            value={showPreloader ? "preloader" : "no-preloader"}
        >
            <GlobalStyle shouldDisableScroll={menuOpen ? true : false} />
            {showPreloader && NODE_ENV !== "development" && (
                <Preloader showPreloader={e => setShowPreloader(e)} />
            )}
            <LangProvider>
                <Header
                    lang={langSlug}
                    prismicTheme={prismicTheme}
                    location={location}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    shortNavVisible={shortNavVisible}
                />
                <Menu
                    lang={langSlug}
                    location={location}
                    prismicTheme={prismicTheme}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                />
                <AnimatePresence>
                    <motion.main
                        key={location.pathname}
                        variants={variants}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                    >
                        {children}
                    </motion.main>
                </AnimatePresence>
                <Footer
                    lang={langSlug}
                    prismicTheme={prismicTheme}
                    prismicFooter={prismicFooter}
                    prismicColors={prismicColors}
                />
            </LangProvider>
        </PreloaderContext.Provider>
    )
}

export default Layout
