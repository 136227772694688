import React from 'react'
import styled from 'styled-components'

const StyledLine = styled.span`
    display: inline-block;
    vertical-align: middle;

    width: 1.5rem;
    height: 1px;

    margin-right: 1rem;

    background-color: ${props => props.theme.colors.white};

    opacity: 0.35;

    ${props => props.theme.above.desktop`
        width: 2.5rem;

        margin-right: 1.5rem;
    `}
`

const Line = ({
    className
}) => {
    return (
        <StyledLine className={`line ${className}`} />
    )
}

export default Line
