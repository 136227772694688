import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import ProductShortcut from "./ProductShortcut"
import VerticalTitle from "./VerticalTitle"

const Wrapper = styled.div`
    position: relative;

    width: 100%;
`

const StyledImage = styled(Img)`
    position: relative;

    width: 100%;
    height: 100%;

    .gatsby-image-wrapper {
        img {
            opacity: 1 !important;
        }
    }
`

const LinkedImage = ({ lang, className, product, image, loading }) => {
    return (
        <Wrapper className={className}>
            <StyledImage
                objectFit="cover"
                fluid={image.fluid}
                alt={image.alt}
                className="image"
            />
            <VerticalTitle title={image.alt} />
            {product && <ProductShortcut lang={lang} product={product} />}
        </Wrapper>
    )
}

export default LinkedImage
