import { useContext } from "react"

export const generatePath = (lang, slug) => {
    let path

    if (!lang) {
        if (slug) {
            path = `/${slug}`
        } else {
            path = "/"
        }
    } else {
        path = `/${lang}/${slug}`
    }

    return path
}

export const generateSlug = text => text.replace(" ", "-").toLowerCase()

export const padLeft = number => number.toString().padStart(2, "0")

export const camelize = str => {
    return str
        .split(" ")
        .map(function(word) {
            return word.charAt(0).toUpperCase() + word.slice(1)
        })
        .join("")
}

export const getBlogPostLink = slug => {
    let link =
        slug.split("/")[0] == "en"
            ? `${slug.split("/")[1]}/${slug.split("/")[2]}`
            : slug
    let url = `https://livingcreations.com/${link}`
    return url
}

export const makeCancelable = promise => {
    let hasCanceled_ = false

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(val =>
            hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
        )
        promise.catch(error =>
            hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
        )
    })

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true
        },
    }
}

export const getPixelRatio = context => {
    var backingStore =
        context.backingStorePixelRatio ||
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio ||
        1

    return (window.devicePixelRatio || 1) / backingStore
}

export const getCurrentLang = () => {
    return (
        (typeof window !== "undefined" &&
            window.localStorage.getItem("currentLang")) ||
        "en"
    )
}
