import React, { useRef, useState, useEffect } from "react"
// import { useSpring, animated } from 'react-spring'
import styled from "styled-components"
import Imgix from "react-imgix"
import { motion } from "framer-motion"

import { Socials } from "../Shared"
import Navigation from "./Navigation"
import LanguageSwitch from "../LanguageSwitch"
import VerticalTitle from "../VerticalTitle"

const StyledMenu = styled(motion.aside)`
    position: fixed;

    top: 0;
    left: 0;

    z-index: 3;

    width: 100%;
    height: 100%;

    padding: 6.5rem 1.5rem 1.5rem;

    background-color: ${props => props.theme.colors.white};

    pointer-events: ${props => (props.open ? "auto" : "none")};

    ${props => props.theme.above.desktop`
        padding: 0;

        transform: none;
    `}
`

const ImageWrapper = styled(motion.div)`
    display: none;

    ${props => props.theme.above.desktop`
        display: inline-block;
        vertical-align: middle;

        position: relative;

        width: 40vw;
        height: 100%;

        background-color: #f1f1f1;

        overflow: hidden;
    `}
`

const NavigationWrapper = styled.div`
    display: block;
    position: relative;

    width: 100%;

    background-color: ${props => props.theme.colors.white};

    ${props => props.theme.above.desktop`
        display: inline-flex;
        vertical-align: middle;

        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        width: 60vw;
        height: 100%;

        padding-top: 15vw;
        padding-left: 15vw;
    `}
`

const Item = styled.div`
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    &.active {
        .image {
            opacity: 1;
            z-index: 2;
            transform: scale(1);
        }
    }
`

const StyledImage = styled(Imgix)`
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;

    opacity: 1;
    transform: scale(1.02);

    transition: all 2s ${props => props.theme.transitions.cubic};
`

const StyledNavigation = styled(Navigation)``

const StyledSocials = styled(Socials)`
    background-color: ${props => props.theme.colors.white};

    padding: 0 1.5rem;

    color: ${props => props.theme.colors.dark};

    .item {
        opacity: 1;
    }

    ${props => props.theme.above.desktop`
        position: absolute;

        bottom: 6rem;
        right: 6rem;

        padding: 0;
    `}
`

const wrapperVariants = {
    open: {
        opacity: 1,
    },
    closed: {
        opacity: 0,
    },
}

const imageVariants = {
    open: {
        x: 0,
        opacity: 1,
    },
    closed: {
        x: -50,
        opacity: 0,
    },
}

const languageVariants = {
    open: {
        y: 0,
        opacity: 1,
    },
    closed: {
        y: -50,
        opacity: 0,
    },
}

const MenuBottom = styled.div``

const LanguageWrapper = styled(motion.div)`
    display: none;

    ${props => props.theme.above.desktop`
        display: block;
        
        position: absolute;
        
        bottom: 6rem;
        left: 6rem;
        
        width: 20rem;
    `}
`

const Menu = ({
    lang,
    location,
    prismicTheme: {
        data: { nav, menu_links },
    },
    menuOpen,
    setMenuOpen,
}) => {
    const [randomIndex, setRandomIndex] = useState(
        Math.floor(Math.random() * nav.length)
    )
    const [randomImage, setRandomImage] = useState(nav[randomIndex])

    useEffect(() => {
        setRandomImage(
            nav[Math.floor(Math.random() * nav.length)].primary.image
        )
    }, [])

    return (
        <StyledMenu
            open={menuOpen}
            initial="closed"
            animate={menuOpen ? "open" : "closed"}
            variants={wrapperVariants}
            transition={{
                duration: 0.15,
                damping: 100,
                stiffness: 100,
                ease: "circInOut",
            }}
        >
            <ImageWrapper
                animate={menuOpen ? "open" : "closed"}
                initial="open"
                variants={imageVariants}
                transition={{
                    duration: 0.5,
                    damping: 100,
                    stiffness: 100,
                    ease: "circOut",
                }}
            >
                {randomImage.url && (
                    <>
                        <StyledImage
                            className="image"
                            src={randomImage.url}
                            sizes="40vw"
                            alt={randomImage.alt}
                        />
                        <VerticalTitle title={randomImage.alt} />
                    </>
                )}
            </ImageWrapper>
            <NavigationWrapper>
                <StyledNavigation
                    data={nav}
                    lang={lang}
                    setMenuOpen={setMenuOpen}
                    menuOpen={menuOpen}
                />
                <MenuBottom>
                    <LanguageWrapper
                        animate={menuOpen ? "open" : "closed"}
                        initial="closed"
                        variants={languageVariants}
                        transition={{
                            duration: 0.5,
                            damping: 100,
                            stiffness: 100,
                            ease: "circOut",
                        }}
                    >
                        <LanguageSwitch showBorder={true} />
                    </LanguageWrapper>
                    <StyledSocials
                        data={menu_links}
                        animation={menuOpen ? "after" : "before"}
                    />
                </MenuBottom>
            </NavigationWrapper>
        </StyledMenu>
    )
}

export default Menu
