import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import YouTube from 'react-youtube'

const Wrapper = styled.div`
    position: relative;

    ${props => props.type == 'banner' && `
        width: 100%;
        height: 100vh;

        .video-foreground {
            pointer-events: none;

            @media (min-aspect-ratio: 16/9) {
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);

                width: 100%;
                height: 150%;
            }

            @media (max-aspect-ratio: 16/9) {
                left: -100%;

                width: 300%; 
                height: 110%;
            }

            @media screen and (max-width: 500px) {
                iframe {
                    width: 120%;
                }
            }

            iframe {
                pointer-events: none;
            }

            ${props.theme.below.desktop`
                left: -240%;
                
                width: 420%;
                height: 120%;
            `}
        }
    `}

    ${props => props.type == 'how-to' && `
        width: 100%;
        height: 100%;
        
        padding-bottom: 56.25%;
    `}
`

const VideoForeground = styled.div`
    position: absolute;
    
    top: 0;
    left: 0;
    
    width: 100%;
    height: 100%;
`

const VideoBackground = styled.div`
    position: absolute;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    
    z-index: 1;
    
    overflow: hidden;
    
    background: ${props => props.theme.colors.darkLight};

    iframe {
        position: absolute;
        
        top: 0;
        left: 0;
        
        width: 100%;
        height: 100%;
    }
`

const Poster = styled(Img)`
    position: absolute !important;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;

    transition: opacity 0.5s cubic-bezier(.82,-.02,.45,1.22);

    opacity: 1;

    z-index: 1;

    ${props => props.videoPlaying == 'true' ? `
        opacity: 0;
    ` : `
        opacity: 1;
    `}
`

const Overlay = styled.div`
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: #000;

    transition: opacity 1s cubic-bezier(.82,-.02,.45,1.22);

    pointer-events: none;
    
    ${props => props.videoPlaying == 'true' ? `
        opacity: 0;
    ` : `
        opacity: 1;
    `}
`

const YoutubeVideo = ({
    className, 
    data, 
    type
}) => {

    const [player, setPlayer] = useState(false)
    const [posterSource, setPosterSource] = useState(type == 'banner' && data.medium[0])
    const [timer, setTimer] = useState(null)
    const [videoLoaded, setVideoLoaded] = useState(false)
    const [videoPlaying, setVideoPlaying] = useState(false)

    const videoOptions = {
        playerVars: {
            autoplay: 1,
            controls: type == 'banner' ? 0 : 1,
            rel: 0,
            loop: 1,
            showinfo: 0,
            playsinline: 1,
            enablejsapi: 1,
            origin: 'https://livingcreations.com/'
        }
    }

    useEffect(() => {

        if (type != 'banner') return
        
        const getBannerSource = width => {
            if (width >= 800) return data.large[0]
            if (width >= 434) return data.medium[0]
            return data.small[0]
        }
        
        setPosterSource(type == 'banner' ? getBannerSource(typeof window != 'undefined' ? window.innerWidth : 1000) : data.medium[0])

        return () => {
            if (timer) clearTimeout(timer)
        }

    }, [])

    const videoReadyHandler = e => {
        e.target.mute()
        e.target.playVideo()
        setPlayer(e.target)
        
        setTimer(setTimeout(() => {
            setVideoLoaded(true)
        }, 25))
    }

    const videoEndHandler = e => {
        e.target.playVideo()
    }

    const videoStateChangeHandler = () => {
        switch (player.getPlayerState()) {
            case -1:
            case 0:
            case 2:
                setVideoPlaying(false)
                break;
            case 1:
                setVideoPlaying(true)

                if (player.getPlaybackQuality() != 'hd1080') {
                    player.setPlaybackQuality('hd1080')
                }
                
                break;
        }
    }

    return (
        <Wrapper type={type} className={className}>
            <VideoBackground className='video-background'>
                <VideoForeground className='video-foreground'>
                    <YouTube
                        videoId={data.youtube_id}
                        opts={videoOptions}
                        className='video-iframe'
                        onReady={videoReadyHandler}
                        onEnd={videoEndHandler}
                        onStateChange={videoStateChangeHandler}
                    />
                </VideoForeground>
            </VideoBackground>
            {type == 'banner' ? (
                <Poster
                    videoPlaying={videoPlaying.toString()}
                    fluid={posterSource.placeholder.fluid}
                    alt={posterSource.placeholder.alt}
                    loading='eager'
                />
            ) : (
                <Overlay 
                    videoPlaying={videoPlaying.toString()}
                />
            )}
        </Wrapper>
    )
}

export default YoutubeVideo
