import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { getPixelRatio } from './../../utils/helpers'

const StyledCanvas = styled.canvas`
    position: fixed;
    
    top: 0;
    left: 0;
    
    z-index: 99;

    width: 100vw;
    height: 100vh;
    
    padding: 0;
    margin: 0;
    
    pointer-events: none;
    // mix-blend-mode: overlay;
`

const Canvas = () => {

    const [dimensions, setDimensions] = useState({ 
        width: typeof window != 'undefined' && window.innerWidth, 
        height: typeof window != 'undefined' && window.innerHeight
    })

    const canvasRef = React.useRef(null)

    useEffect(() => {

        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        const ratio = getPixelRatio(ctx)

        let width = dimensions.width
        let height = dimensions.height

        canvas.width = width * ratio
        canvas.height = height * ratio
        canvas.style.width = `${width}px`
        canvas.style.height = `${height}px`

        const render = (e) => {
            const { pageX, pageY } = e
            const hoverState = e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button'
            const innerSize = hoverState ? 0 : 5
            const outerSize = hoverState ? 0 : 20

            ctx.beginPath()
            ctx.clearRect(0, 0, width, height)
            ctx.arc(pageX, pageY, innerSize, 0, 2 * Math.PI)
            ctx.fillStyle = '#fff'
            ctx.fill()

            // setTimeout(() => {
                ctx.beginPath()
                ctx.arc(pageX, pageY, outerSize, 0, 2 * Math.PI)
                ctx.strokeStyle = 'rgba(255, 255, 255, 0.15)'
                ctx.stroke()
            // }, 100)
        }

        window.addEventListener('mousemove', render)

        return () => {
            window.removeEventListener('mousemove', render)
        }
    }, [])

    return (
        <StyledCanvas
            ref={canvasRef}
            width={dimensions.width}
            height={dimensions.height}
        >
            
        </StyledCanvas>
    )
}

export default Canvas
