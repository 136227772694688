import React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

import LinkedImage from "./../LinkedImage"

const Wrapper = styled(motion.div)`
    width: 100%;
    height: 100%;
`

const StyledLinkedImage = styled(LinkedImage)`
    width: 100%;
    height: 100%;

    picture {
        img {
            opacity: 1 !important;
        }
    }
`

const Carousel = ({ type, className, data, lang }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    })

    const params = {
        centeredSlides: true,
        initialSlide: 1,
        loop: true,
        preloadImages: false,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 0,
            },
            768: {
                slidesPerView: 1.5,
                spaceBetween: 60,
            },
            1023: {
                slidesPerView: 1.5,
                spaceBetween: 90,
            },
            2000: {
                slidesPerView: 2.5,
                spaceBetween: 90,
            },
        },
    }

    return (
        <Wrapper
            ref={ref}
            className={className + type}
            animate={inView ? "after" : "before"}
            variants={{
                before: {
                    opacity: 0,
                    y: 100,
                },
                after: {
                    opacity: 1,
                    y: 0,
                },
            }}
            transition={{
                duration: 0.55,
                damping: 100,
                stiffness: 100,
                ease: "circOut",
            }}
        >
            <Swiper {...params}>
                {data.items.map((image, index) => (
                    <StyledLinkedImage
                        key={index}
                        lang={lang}
                        product={
                            image.product_link && image.product_link.document
                        }
                        image={image.image}
                    />
                ))}
            </Swiper>
        </Wrapper>
    )
}

export default Carousel
