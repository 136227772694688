import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import CrossSVG from "./../graphics/button-cross.svg"

import { generatePath } from "./../utils/helpers"

const Wrapper = styled(motion.div)`
    position: absolute;

    bottom: 1.5rem;
    right: 1.5rem;

    z-index: 2;

    ${props => props.theme.above.desktop`
        display: block;
        
        bottom: 3rem;
        right: 3rem;
    `}
`

const StyledLink = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    height: 5rem;

    padding: 0 0.75rem;

    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 5rem;

    ${props =>
        props.active == "true" &&
        `
        .circle {
            background-color: ${props.theme.colors.dark};
        }

        .label-wrapper {
            display: block;
        }

        .icon {
            color: ${props.theme.colors.white};
        }

        background-color: rgba(255, 255, 255, 1);
    `}

    ${props =>
        props.reversed &&
        `
        flex-direction: row-reverse;
    `}
`

const LabelWrapper = styled.div`
    display: none;
`

const Label = styled.span`
    position: relative;

    margin-right: 1rem;

    padding-left: 2rem;
    padding-right: 0.5rem;

    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 1.3rem;

    color: ${props => props.theme.colors.dark};
`

const Circle = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    transform: rotate(45deg);
    transform-origin: center;

    background-color: ${props => props.theme.colors.white};

    height: 3.5rem;
    width: 3.5rem;

    border-radius: 100%;

    transition: all 0.35s ${props => props.theme.transitions.cubic};
`

const StyledIcon = styled(CrossSVG)`
    width: 1.1rem;
    height: 1.1rem;

    color: ${props => props.theme.colors.dark};

    line {
        fill: currentColor;
        stroke: currentColor;
        stroke-width: 0.1rem;
    }
`

const ProductShortcut = props => {
    const {
        lang,
        className,
        open,
        product: {
            uid,
            data: { product_family, product_title },
        },
        noAnimation,
        reversed = false,
    } = props

    if (!uid) return null

    const [active, setActive] = useState(open ? open : false)

    const [ref, inView] = useInView({
        triggerOnce: false,
    })

    let animation = inView ? "after" : "before"

    if (noAnimation) animation = "after"

    return (
        <Wrapper
            ref={ref}
            className={className}
            onMouseEnter={() => setActive(!active)}
            onMouseLeave={() => setActive(!active)}
            animate={animation}
            variants={{
                before: {
                    opacity: 0,
                    scale: 0,
                },
                after: {
                    opacity: 1,
                    scale: 1,
                },
            }}
            transition={{
                duration: 0.55,
                damping: 100,
                stiffness: 100,
                ease: "circOut",
            }}
        >
            <StyledLink
                to={generatePath(
                    lang,
                    `collection/${product_family.slug}/${uid}`
                )}
                active={active.toString()}
                reversed={reversed}
            >
                <LabelWrapper className="label-wrapper">
                    <Label className="label">
                        {product_title.text.replace("®", "")}
                    </Label>
                </LabelWrapper>
                <Circle className="circle">
                    <StyledIcon className="icon" />
                </Circle>
            </StyledLink>
        </Wrapper>
    )
}

export default ProductShortcut
