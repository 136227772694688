import React from "react"
import styled, { css } from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import Line from "./Line"

const Wrapper = styled.div`
    width: 100%;
    max-width: 80rem;

    margin: ${props =>
        props.alignment == "center" ? "0 auto 3rem auto" : "0 0 3rem 0"};
    padding: 0 3rem;
    
    text-align: left;
    
    ${props => props.theme.above.desktop`
        margin-bottom: 12rem;
        padding: 0 6rem;
        
        text-align: ${props.alignment};
    `}

    /* ${props => props.theme.below.desktop`
        padding: 0 0;
    `} */
`

const TitleWrapper = styled.div`
    position: relative;

    overflow: hidden;
`

const TitleStyles = css`
    display: block;

    margin-bottom: 0.5rem;

    ${props => props.theme.above.desktop`
        margin-bottom: 1rem;
    `}
`

const LargeTitle = styled(motion.h1)`
    ${TitleStyles}
`

const MediumTitle = styled(motion.h3)`
    ${TitleStyles}
`

const Subtitle = styled(motion.div)`
    margin-bottom: 2rem;
`

const SubtitleLabel = styled.span`
    display: inline-block;
    vertical-align: middle;
`

const SubtitleLink = styled(motion.a)`
    display: block;

    margin-bottom: 1rem;

    transition: all 0.35s ${props => props.theme.transitions.cubic};

    &:hover {
        color: ${props => props.theme.colors.white};
    }

    ${props => props.theme.above.desktop`
        color: ${props => props.theme.colors.darkText};
    `}
`

const Description = styled(motion.div)``

const Paragraph = styled(motion.p)`
    margin-bottom: 2rem;

    a {
        display: inline-block;

        font-family: ${props => props.theme.fontFamily.neueMontrealRegular};
        letter-spacing: 0.12rem;

        ${props => props.theme.underline(props.theme.colors.white, "1px")}

        color: ${props => props.theme.colors.white};
    }

    strong {
        font-weight: 700;
        color: ${props => props.theme.colors.white};
    }

    em {
        font-style: italic;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    ${props => props.theme.above.desktop`
        a {
            font-size: 1.6rem;
        }

        /* ${props.large &&
            `
            font-size: 2.2rem;
            line-height: 3.2rem;
        `} */
    `}
`

const titleVariants = {
    before: {
        opacity: 0,
        skewY: 3,
        y: 100,
    },
    after: {
        opacity: 1,
        skewY: 0,
        y: 0,
    },
}

const transition = {
    duration: 0.5,
    damping: 100,
    stiffness: 100,
    ease: "circOut",
}

const subtitleVariants = {
    before: {
        opacity: 0,
    },
    after: {
        opacity: 1,
    },
}

const subtitleTransition = {
    duration: 0.5,
    delay: 0.5,
}

const TitleComponent = ({
    className,
    alignment,
    title,
    subtitle,
    href,
    description,
    header,
    html,
    htmlTitle,
    scroll,
    text,
    animation
}) => {
    
    const [ref, inView] = useInView({
        triggerOnce: false,
    })

    animation = animation || (inView ? "after" : "before")

    if (scroll) {
        return (
            <Wrapper
                ref={ref}
                className={
                    className ? `title-wrapper ${className}` : `title-wrapper`
                }
                alignment={alignment}
            >
                {subtitle && (
                    <Subtitle
                        className="subtitle"
                        initial="before"
                        animate={animation}
                        variants={subtitleVariants}
                        transition={subtitleTransition}
                    >
                        <Line />
                        <SubtitleLabel className="subtitle">
                            {subtitle}
                        </SubtitleLabel>
                    </Subtitle>
                )}
                {htmlTitle ? (
                    <LargeTitle
                        className="title"
                        dangerouslySetInnerHTML={{ __html: title }}
                        animate={animation}
                        variants={titleVariants}
                        transition={transition}
                    />
                ) : (
                    <LargeTitle
                        className="title"
                        animate={animation}
                        variants={titleVariants}
                        transition={transition}
                    >
                        {title}
                    </LargeTitle>
                )}
                {description && html && (
                    <Paragraph
                        animate={animation}
                        initial="before"
                        variants={{
                            before: {
                                opacity: 0,
                                y: -25,
                            },
                            after: {
                                opacity: 1,
                                y: 0,
                            },
                        }}
                        transition={transition}
                        className="paragraph"
                        large
                        dangerouslySetInnerHTML={{
                            __html: description.html || description,
                        }}
                    />
                )}
                {description && !html && (
                    <Paragraph
                        animate={animation}
                        initial="before"
                        variants={{
                            before: {
                                opacity: 0,
                                y: -25,
                            },
                            after: {
                                opacity: 1,
                                y: 0,
                            },
                        }}
                        transition={transition}
                        className="paragraph"
                        large
                    >
                        {description}
                    </Paragraph>
                )}
            </Wrapper>
        )
    }

    return (
        <Wrapper
            ref={ref}
            className={
                className ? `title-wrapper ${className}` : `title-wrapper`
            }
            alignment={alignment}
        >
            {href && subtitle ? (
                <SubtitleLink
                    className="subtitle"
                    href={href}
                    target="_blank"
                    initial="before"
                    animate={animation}
                    variants={subtitleVariants}
                    transition={subtitleTransition}
                >
                    {subtitle}
                </SubtitleLink>
            ) : subtitle ? (
                <Subtitle
                    className="subtitle"
                    initial="before"
                    animate={animation}
                    variants={subtitleVariants}
                    transition={subtitleTransition}
                >
                    <Line />
                    <SubtitleLabel className="subtitle">
                        {subtitle}
                    </SubtitleLabel>
                </Subtitle>
            ) : null}
            {header ? (
                <TitleWrapper>
                    <LargeTitle
                        className="title"
                        animate={animation}
                        variants={titleVariants}
                        transition={transition}
                    >
                        {title}
                    </LargeTitle>
                </TitleWrapper>
            ) : (
                <TitleWrapper>
                    <MediumTitle
                        className="title"
                        animate={animation}
                        variants={titleVariants}
                        transition={transition}
                    >
                        {title}
                    </MediumTitle>
                </TitleWrapper>
            )}
            {description && (
                <Description
                    className="description"
                    animate={animation}
                    initial="before"
                    variants={{
                        before: {},
                        after: {
                            transition: {
                                staggerChildren: 0.5,
                            },
                        },
                    }}
                >
                    {html && (
                        <Paragraph
                            className="paragraph"
                            initial="before"
                            variants={{
                                before: {
                                    opacity: 0,
                                    y: -25,
                                },
                                after: {
                                    opacity: 1,
                                    y: 0,
                                },
                            }}
                            transition={transition}
                            dangerouslySetInnerHTML={{
                                __html: description.html,
                            }}
                        />
                    )}
                    {!html &&
                        !text &&
                        description.map((paragraph, index) => (
                            <Paragraph
                                key={index}
                                className="paragraph"
                                initial="before"
                                variants={{
                                    before: {
                                        opacity: 0,
                                        y: -25,
                                    },
                                    after: {
                                        opacity: 1,
                                        y: 0,
                                    },
                                }}
                                transition={transition}
                            >
                                {paragraph.text}
                            </Paragraph>
                        ))}
                    {!html && text && (
                        <Paragraph
                            className="paragraph"
                            initial="before"
                            variants={{
                                before: {
                                    opacity: 0,
                                    y: -25,
                                },
                                after: {
                                    opacity: 1,
                                    y: 0,
                                },
                            }}
                            transition={transition}
                        >
                            {description}
                        </Paragraph>
                    )}
                </Description>
            )}
        </Wrapper>
    )
}

export default TitleComponent
