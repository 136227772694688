import { css, keyframes } from 'styled-components'

const breakpoints = {
    mobile: 500,
    desktop: 1023,
    wide: 1440,
    extraWide: 1920
}

const colors = {
    white: '#fff',
    black: '#000',
    dark: '#1A1919',
    darkMedium: '#252525',
    darkLight: '#313030',
    darkLightest: '#353535',
    darkText: '#949494'
}

const fontFamily = {
    circularBook: 'CircularBook',
    circularMedium: 'CircularMedium',
    minionMedium: 'MinionMedium',
    minionSemibold: 'MinionSemibold',
    neueMontrealRegular: 'NeueMontrealRegular',
    neueMontrealMedium: 'NeueMontrealMedium'
}

const easeInOut = {
    sine: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
    quad: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
    cubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    quart: 'cubic-bezier(0.77, 0, 0.175, 1)',
    quint: 'cubic-bezier(0.86, 0, 0.07, 1)',
    expo: 'cubic-bezier(1, 0, 0, 1)'
}

const easeIn = {
    sine: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
    quad: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
    cubic: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
    quart: 'cubic-bezier(0.895, 0.03, 0.685, 0.22)',
    quint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
    expo: 'cubic-bezier(0.95, 0.05, 0.795, 0.035)'
}

const easeOut = {
    sine: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
    quad: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
    cubic: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
    quart: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
    quint: 'cubic-bezier(0.23, 1, 0.32, 1)',
    expo: 'cubic-bezier(0.19, 1, 0.22, 1)'
}

const transitions = {
    cubic: 'cubic-bezier(0.16, 1.08, 0.38, 0.98)'
}

const borders = {
    light: '1px solid rgba(255, 255, 255, 0.15)'
}

const above = Object.keys(breakpoints).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media screen and (min-width: ${breakpoints[label]}px) {
            ${css(...args)}
        }
    `
    return acc
}, {})

const below = Object.keys(breakpoints).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media screen and (max-width: ${breakpoints[label]}px) {
            ${css(...args)}
        }
    `
    return acc
}, {})

const theme = {
    breakpoints,
    colors, 
    fontFamily,
    easeInOut,
    easeIn, 
    easeOut,
    transitions,
    borders,
    above,
    below,
    
    underline: (color, height) => css`
        &:after {
            content: '';
            display: block;

            height: ${height};

            margin-top: 1px;
            margin-left: 1px;
            
            background-color: ${color};
            
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.35s cubic-bezier(0.16, 1.08, 0.38, 0.98);
        }

        &:hover {
            &:after {
                transform: scaleX(1);
            }
        }
    `
}

export default theme