import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import Line from './../Line'

import { padLeft } from './../../utils/helpers'

const Wrapper = styled(motion.div)`
    display: none;

    ${props => props.theme.above.desktop`
        display: block;

        margin-bottom: 1rem;
    `}
`

const Label = styled.span`
    display: inline-block;
    vertical-align: middle;

    font-family: ${props => props.theme.fontFamily.circularMedium};
    font-size: 1.1rem;
    letter-spacing: 0.2rem;

    ${props => props.theme.above.desktop`
        font-size: 1.4rem;
    `}
`

const Highlight = styled.strong`
    color: ${props => props.theme.colors.white};
`

const Current = ({
    current, 
    total,
    className,
    animation,
    variants,
    transition
}) => {
    return (
        <Wrapper 
            className={className}
            animate={animation}
            variants={variants}
            transition={transition}
        >
            <Line />
            <Label className='label'>
                <Highlight>{padLeft(current)}</Highlight> / {padLeft(total)}
            </Label>
        </Wrapper>
    )
}

export default Current
