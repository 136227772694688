import React from 'react'
import Provider from './src/components/Layout/Provider'
import Layout from './src/components/Layout/Layout'
import { DictionaryContext } from './src/contexts/dictionary'

const transitionDelay = 500

export const wrapRootElement = ({ element }) => <Provider>{element}</Provider>

export const wrapPageElement = ({
    element,
    props: {
        location,
        pageContext: {
            lang,
            langSlug,
            langSettings,
            prismicDictionary,
            prismicTheme,
            prismicFooter,
            prismicColors
        }
    }
}) => {
    return (
        <DictionaryContext.Provider value={prismicDictionary && prismicDictionary.data}>
            <Layout
                lang={lang}
                langSlug={langSlug}
                langSettings={langSettings}
                location={location}
                prismicTheme={prismicTheme}
                prismicFooter={prismicFooter}
                prismicColors={prismicColors}
            >
                {element}
            </Layout>
        </DictionaryContext.Provider>
    )
}

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    if (location.action === 'PUSH') {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
        const savedPosition = getSavedScrollPosition(location)
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay
        )
    }
    return false
}

export const onClientEntry = async () => {
    if (typeof IntersectionObserver === `undefined`) {
        await import(`intersection-observer`)
    }
}