import React, { useRef, useState, useEffect, useContext } from "react"
import Select from "react-select"

import LangContext from "./../contexts/LangContext"

// import styles from './styles.module.scss'
import theme from "../styles/theme"

const LanguageSwitch = ({ className, mobile = false, showBorder = false }) => {
    const { switchLang, lang } = useContext(LangContext)

    let languageOptions = [
        {
            value: "en",
            label: "🇬🇧  English",
        },
        {
            value: "nl",
            label: "🇳🇱  Nederlands",
        },
        {
            value: "de",
            label: "🇩🇪  Deutsch",
        },
    ]

    let languageLabels = {
        en: "🇬🇧  English",
        nl: "🇳🇱  Nederlands",
        de: "🇩🇪  Deutsch",
    }

    if (mobile) {
        languageOptions = [
            {
                value: "en",
                label: "🇬🇧",
            },
            {
                value: "nl",
                label: "🇳🇱",
            },
            {
                value: "de",
                label: "🇩🇪",
            },
        ]

        languageLabels = {
            en: "🇬🇧",
            nl: "🇳🇱",
            de: "🇩🇪",
        }
    }

    const customStyles = {
        placeholder: provided => ({
            ...provided,
            fontFamily: theme.fontFamily.circularBook,
            fontSize: "1.4rem",
            lineHeight: "2.5rem",
            color: theme.colors.dark,
            opacity: "50",
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: theme.fontFamily.circularBook,
            fontSize: "1.4rem",
            padding: "1.5rem",
            backgroundColor: state.isFocused ? "#eaeaea" : "    ",
            color: state.isFocused ? theme.colors.dark : theme.colors.darkLight,
            "&:active": {
                backgroundColor: "transparent",
                color: theme.colors.dark,
            },
        }),
        input: provided => ({
            ...provided,
            fontFamily: theme.fontFamily.circularBook,
            fontSize: "1.4rem",
        }),
        valueContainer: provided => ({
            ...provided,
            width: "100%",
            padding: mobile ? "0" : "2.25rem 5rem 2.25rem 2rem",
            border: mobile ? "none" : showBorder ? "1px solid #ebebeb" : "none",
            borderRadius: mobile ? 0 : "0.5rem",
            justifyContent: mobile ? "center" : "flex-start",
        }),
        singleValue: provided => ({
            ...provided,
            fontFamily: theme.fontFamily.circularBook,
            fontSize: "1.4rem",
            lineHeight: "2.2rem",
        }),
        control: (provided, state) => ({
            ...provided,
            boxShadow: state.isFocused ? 0 : 0,
            borderColor: "transparent",
            borderRadius: mobile ? 0 : "0.5rem",
            width: mobile ? "5rem" : "auto",
            height: mobile ? "5rem" : "auto",
            "&:hover": {
                borderColor: "transparent",
            },
        }),
        menuList: () => ({
            padding: 0,
            margin: 0,
            textAlign: mobile ? "center" : "left",
        }),
        menu: provided => ({
            ...provided,
            padding: 0,
            margin: "0 0 0.5rem 0",
            borderRadius: mobile ? 0 : "0.5rem",
            overflow: "hidden",
        }),
        indicatorSeparator: () => ({
            display: "none",
            padding: "1.5rem",
        }),
        dropdownIndicator: () => ({
            position: "absolute",
            top: "0",
            right: "0",
            padding: mobile ? "0" : "1.75rem",
            color: theme.colors.dark,
            display: mobile ? "none" : "block",
        }),
        noOptionsMessage: () => ({
            textAlign: "left",
            fontFamily: "Circular Book",
            fontSize: "1.2rem",
            padding: "1.5rem",
        }),
    }

    return (
        // <div className={className}>
        <Select
            styles={customStyles}
            options={languageOptions}
            placeholder={languageLabels[lang] || languageLabels["en"]}
            menuPlacement="top"
            isSearchable={false}
            onChange={e => {
                switchLang(e.value)
            }}
        />
        // </div>
    )
}

export default LanguageSwitch
