import React, { useContext } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { CopyToClipboard } from "react-copy-to-clipboard"

import LinkSVG from "./../../graphics/link.svg"

import { generatePath, getBlogPostLink } from "./../../utils/helpers"
import { DictionaryContext } from "../../contexts/dictionary"

const Wrapper = styled.div`
    position: relative;

    ${props =>
        props.related
            ? `
        background-color: #fff;

        h3 {
            color: #000;
        }
    `
            : `
        background-color: #242424;
    `}

    ${props => props.theme.below.desktop`
        .image-wrapper {
            height: 20rem;
        }
    `}
`

const StyledLink = styled(Link)`
    display: block;

    text-decoration: none;
`

const ImageWrapper = styled.div`
    width: 100%;
    height: 40rem;

    overflow: hidden;
`

const Image = styled(Img)`
    width: 100%;
    height: 100%;

    object-fit: cover;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    padding: 2rem;

    text-align: left;

    ${props => props.theme.above.desktop`
        padding: 4rem;

        ${!props.related &&
            `
            min-height: 40rem;
        `}
    `}
`

const Header = styled.header`
    display: block;

    ${props =>
        props.related
            ? `
        text-align: center;

        .category {
            border: none;
            margin-bottom: 1rem;
        }
    `
            : `
        margin-bottom: 4rem;
    `}
`

const Category = styled.span`
    display: inline-block;

    margin-bottom: 1.5rem;

    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 5px;

    ${props => props.theme.above.desktop`
        border: 1px solid #949494;
        border-radius: 25rem;
        
        margin-bottom: 2.5rem;
        padding: 0.25rem 1.5rem;

        font-size: 1.3rem;
    `}
`

const Title = styled.h3`
    margin-bottom: 1.5rem;

    font-size: 2.5rem;
    line-height: 1.5;

    ${props => props.theme.above.desktop`
        font-size: 3.6rem;
    `}
`

const Excerpt = styled.p`
    color: #fff;

    ${props => props.theme.above.desktop`
        font-size: 1.75rem;
        line-height: 2;
    `}
`

const Footer = styled.footer`
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;

    justify-content: space-between;

    ${props =>
        props.related &&
        `
        .footer-link {
            display: none;
        }
    `}

    ${props => props.theme.above.desktop`
        ${!props.related &&
            `
            position: absolute;

            left: 0;
            bottom: 0;

            padding: 4rem;

            justify-content: space-between;
        `}
    `}
`

const FooterLabel = styled.span`
    display: inline-block;
    vertical-align: middle;

    color: #949494;

    text-transform: capitalize;
`

const FooterLink = styled.span`
    display: flex;
    align-items: center;

    color: #949494;

    svg {
        margin-right: 1rem;
    }
`

const Item = ({ data, related, uid, lang }) => {
    const dictionary = useContext(DictionaryContext)
    return (
        <Wrapper related={related}>
            <StyledLink to={generatePath(lang, `blogs-and-inspiration/${uid}`)}>
                <ImageWrapper>
                    {data.post_image[0].image.fluid && (
                        <Image
                            fluid={data.post_image[0].image.fluid}
                            alt={data.post_title.text}
                        />
                    )}
                </ImageWrapper>
                <Content related={related}>
                    <Header related={related}>
                        <Category className="category">
                            {data.post_category}
                        </Category>
                        <Title>{data.post_title.text}</Title>
                        {!related && (
                            <Excerpt>{data.post_excerpt.text}</Excerpt>
                        )}
                    </Header>
                    <Footer related={related}>
                        <FooterLabel>{data.postdate_en}</FooterLabel>
                        <CopyToClipboard
                            text={getBlogPostLink(
                                `blogs-and-inspiration/${uid}`
                            )}
                            style={{ cursor: "pointer" }}
                        >
                            <FooterLink className="footer-link">
                                <LinkSVG />
                                <span>Copy link</span>
                            </FooterLink>
                        </CopyToClipboard>
                    </Footer>
                </Content>
            </StyledLink>
        </Wrapper>
    )
}

export default Item
