import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Img from 'gatsby-image'

import ProductShortcut from './../ProductShortcut'

const StyledVideo = styled(motion.div)`
    position: relative;
    
    height: 100%;
    width: 100%;

    background-color: ${props => props.theme.colors.dark};

    ${props => props.overlayAmount && `
        &:after {
            content: '';
            position: absolute;

            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            background-color: rgba(0, 0, 0, ${props.overlayAmount / 10});
        }   
    `}
`

const VideoPlayer = styled.video`
    position: relative;

    height: 100%;
    width: 100%;

    object-fit: cover;

    &::-webkit-media-controls {
        display: none !important;
        -webkit-appearance: none;
    }
`

const StyledImage = styled(Img)`
    position: absolute !important;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;

    transition: opacity 0.35s ${props => props.theme.transitions.cubic};
`

const StyledProductShortcut = styled(ProductShortcut)`
    bottom: 1.5rem;
    right: 1.5rem;

    ${props => props.theme.above.desktop`
        bottom: 3rem;
        right: 3rem;
    `}
`

const Video = ({ 
    lang,
    className,
    overlayAmount,
    data,
    product,
    type,
    animation,
    delay
}) => {    

    const videoEl = useRef()
    const [ready, setReady] = useState(false)
    const [source, setSource] = useState(data.medium[0])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (videoEl.current.readyState >= 3) {
                videoEl.current.play()
                setReady(true)
                clearInterval(intervalId)
            }
        }, 100)

        return () => clearInterval(intervalId)
    }, [])

    useEffect(() => {
        const getBannerSource = width => {
            if (width >= 800) return data.large[0]
            if (width >= 434) return data.medium[0]
            return data.small[0]
        }

        setSource(type == 'banner' ? getBannerSource(typeof window != 'undefined' ? window.innerWidth : 1000) : data.medium[0])
    }, [])

    const overlayFallback = type == 'banner' ? 0.2 : 0
    
    return (
        <StyledVideo 
            className={className} 
            overlayAmount={overlayAmount || overlayFallback}
            animate={animation}
            initial='before'
            variants={{
                before: {
                    opacity: 0
                },
                after: {
                    opacity: 1
                }
            }}
            transition={{
                duration: type == 'banner' ? 4 : 1,
                delay: delay || 0,
                damping: 100,
                stiffness: 500,
                ease: 'circOut'
            }}
        >
            <VideoPlayer
                loop
                muted
                autoPlay
                playsInline
                onLoadedData={() => !ready && setReady(true)}
                ref={videoEl}
            >
                <source src={source.webm.url} type='video/webm' />
                <source src={source.mp4.url} type='video/mp4' />
            </VideoPlayer>
            <StyledImage
                style={{ opacity: ready ? 0 : 1 }}
                fluid={source.placeholder.fluid}
                alt={source.placeholder.alt}
            />
            {product &&
                <StyledProductShortcut
                    lang={lang}
                    product={product}
                />
            }
        </StyledVideo>
    )
}

export default Video
