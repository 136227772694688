import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { StaticQuery, graphql } from "gatsby"

const StyledPreloader = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: fixed;
    
    top: 0;
    left: 0;
    
    z-index: 100;

    width: 100vw;
    height: 100vh;
    
    pointer-events: none;

    background-color: ${props => props.theme.colors.dark};

    ${props => props.theme.below.desktop`
        height: calc(var(--vh, 1vh) * 100);

        max-height: 800px;
    `}
`

const Wrapper = styled.div`
    width: 12rem;
    height: 12rem;

    margin-bottom: 5rem;

    overflow: hidden;
`

const StyledMark = styled(motion.img)`
    width: 100%;
    height: 100%;
`

const Line = styled(motion.div)`
    position: relative;

    width: 12rem;
    height: 0.2rem;

    overflow: hidden;

    background-color: rgba(255, 255, 255, 0.15);
`

const Loader = styled(motion.span)`
    position: absolute;

    top: 0;
    left: 0;
    
    width: 0;
    height: 0.2rem;

    background-color: #fff;
`

const Preloader = ({
    className,
    showPreloader
}) => {

    const preloaderRef = useRef(null)

    useEffect(() => {

        let vh = typeof window !== 'undefined' ? window.innerHeight * 0.01 : 1000
        preloaderRef.current.style.setProperty('--vh', `${vh}px`)

    }, [])

    return (
        <StaticQuery query={graphql`
            query {
                file(relativePath: {eq: "start.gif"}) {
                    publicURL
                }
            }
        `}
        render={({ file }) => {
            return (
                <StyledPreloader 
                    className={className}
                    ref={preloaderRef}
                    initial={{
                        opacity: 1
                    }}
                    animate={{
                        opacity: 0
                    }}
                    transition={{
                        duration: 0.75,
                        delay: 3.25,
                        ease: 'easeInOut'
                    }}
                >
                    <Wrapper>
                        <StyledMark 
                            src={file.publicURL} 
                            alt='Living Creations - Mark'
                            initial={{
                                opacity: 1
                            }}
                            animate={{
                                opacity: 0
                            }}
                            transition={{
                                duration: 0.55,
                                delay: 2.55,
                                damping: 100,
                                stiffness: 100,
                                ease: 'easeOut'
                            }}
                            onAnimationComplete={() => showPreloader()}
                        />
                    </Wrapper>
                    <Line
                        initial={{
                            opacity: 1
                        }}
                        animate={{
                            opacity: 0
                        }}
                        transition={{
                            duration: 0.5,
                            delay: 2.2,
                            ease: 'easeInOut'
                        }} 
                    >
                        <Loader
                            initial={{
                                width: '0%'
                            }}
                            animate={{
                                width: '100%'
                            }}
                            transition={{
                                duration: 1.5,
                                damping: 100,
                                stiffness: 100,
                                ease: 'linear'
                            }}
                        />
                    </Line>
                </StyledPreloader>
            )
        }}
        />
    )
}

export default Preloader
