import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import { generatePath } from "../../utils/helpers"

import Award from "./../Award"
import ButtonRounded from "./../Buttons/ButtonRounded"

const StyledItem = styled(motion.li)`
    position: relative;

    width: 100%;

    ${props => props.theme.above.desktop`
        &:hover {
            .image {
                transform: scale(1.05);
            }
            
            .award {
                transform: translateY(0);
            }
        }
    `}
`

const StyledLink = styled(Link)`
    display: block;
`

const ImageWrapper = styled.div`
    position: relative;

    margin-bottom: 2rem;

    background-color: ${props => props.theme.colors.darkMedium};

    overflow: hidden;

    ${props => props.theme.above.desktop`
        height: 30vw;
        max-height: 56rem;

        margin-bottom: 3rem;

        ${props =>
            props.overlay == "true" &&
            `
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0.55) 0%, transparent 25%);
            }
        `}
    `}
`

const StyledImage = styled(Img)`
    width: 100%;
    height: 100%;

    .gatsby-image-wrapper {
        img {
            opacity: 1 !important;
        }
    }

    transition: transform 2s ${props => props.theme.transitions.cubic};
`

const Header = styled.div`
    margin-bottom: 2rem;
    padding: 0 1rem;

    text-align: center;

    ${props => props.theme.above.desktop`
        margin-bottom: 1rem;
    `}
`

const Title = styled.h4`
    display: block;

    margin-bottom: 0.5rem;

    font-size: 1.4rem;
    line-height: 1.8rem;

    ${props => props.theme.above.desktop`
        font-size: 2.5rem;
        line-height: 3rem;
    `}
`

const Family = styled.span`
    display: block;

    font-size: 1.1rem;
    line-height: 1.2rem;

    ${props => props.theme.above.desktop`
        font-size: 1.3rem;
        line-height: 1.5rem;
    `}
`

const StyledButtonRounded = styled(ButtonRounded)`
    display: none;

    position: absolute;

    top: 1.5rem;
    left: 50%;

    transform: translateX(-50%);

    padding: 0;

    background-color: transparent;

    ${props => props.theme.above.desktop`
        display: block;

        top: 2vw;
    `}
`

const Placeholder = styled.div`
    width: 100%;
    height: 80vw;

    margin-bottom: 1.5rem;

    background-color: ${props => props.theme.colors.darkLight};

    ${props => props.theme.above.desktop`
        height: 30vw;

        margin-bottom: 3rem;
    `}
`

const StyledAward = styled(Award)`
    position: absolute;

    top: 0;
    right: 2rem;
    width: auto;

    ${props => props.theme.below.desktop`
        img {
            max-width: 3rem;
        }
    `}

    ${props => props.theme.above.desktop`
        right: 3rem;

        transform: translateY(-100%);

        transition: transform 0.15s ease-in-out;
    `}
`

const Item = ({
    lang,
    uid,
    className,
    data: { product_title, product_family, product_tag, medium, product_awards_group },
    initial,
    variants,
    transition,
}) => {
        return (
        <StyledItem
            className={className}
            initial={initial}
            variants={variants}
            transition={transition}
        >
            <StyledLink
                to={generatePath(
                    lang,
                    `collection/${product_family.slug}/${uid}`
                )}
            >
                {medium ? (
                    <ImageWrapper
                        className="image-wrapper"
                        overlay={product_tag != null ? "true" : "false"}
                    >
                        <StyledImage
                            className="image"
                            fluid={medium.fluid}
                            alt={medium.alt}
                            style={{
                                opacity: 1,
                            }}
                            imgStyle={{
                                opacity: 1,
                            }}
                            placeholderStyle={{
                                opacity: 0,
                            }}
                            fadeIn={false}
                            loading="eager"
                        />
                        {product_awards_group && product_awards_group.length > 0 && (
                            <StyledAward awards={product_awards_group} />
                        )}
                    </ImageWrapper>
                ) : (
                    <Placeholder />
                )}
                <Header>
                    <Title className="title">{product_title.text}</Title>
                    <Family className="family">
                        {product_family.document.data.title.text}
                    </Family>
                </Header>
                {product_tag != null && (
                    <StyledButtonRounded
                        label={product_tag.document.data.title.text}
                    />
                )}
            </StyledLink>
        </StyledItem>
    )
}

export default Item
