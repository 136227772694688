import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

import CircularBook from './../../fonts/CircularStd-Book.woff'
import CircularMedium from './../../fonts/CircularStd-Medium.woff'
import MinionMedium from './../../fonts/MinionPro-MediumCapt.woff'
import MinionSemibold from './../../fonts/MinionPro-SemiboldCapt.woff'
import NeueMontrealRegular from './../../fonts/NeueMontreal-Regular.woff'
import NeueMontrealMedium from './../../fonts/NeueMontreal-Medium.woff'

const GlobalStyle = createGlobalStyle`
	${reset}

	@font-face {
        font-family: 'CircularBook';
		src: url('${CircularBook}') format('woff');
		font-style: normal;
	}
	
	@font-face {
        font-family: 'CircularMedium';
		src: url('${CircularMedium}') format('woff');
		font-style: normal;
	}
	
	@font-face {
        font-family: 'MinionMedium';
		src: url('${MinionMedium}') format('woff');
		font-style: normal;
	}
	
	@font-face {
        font-family: 'MinionSemibold';
		src: url('${MinionSemibold}') format('woff');
		font-style: normal;
	}
	
	@font-face {
        font-family: 'NeueMontrealRegular';
		src: url('${NeueMontrealRegular}') format('woff');
		font-style: normal;
	}
	
	@font-face {
        font-family: 'NeueMontrealMedium';
		src: url('${NeueMontrealMedium}') format('woff');
		font-style: normal;
	}

	html {
		font-size: 62.5%;

		${props => props.theme.above.desktop`
			font-size: 50%;
		`}

		${props => props.theme.above.wide`
			font-size: 65.2%;
		`}
	}

	body {
		font-family: 'NeueMontrealRegular', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		overflow: ${props => (props.shouldDisableScroll ? 'hidden' : 'auto')};

		background-color: ${props => props.background == 'light' ? props.theme.colors.white : props.theme.colors.dark};
		color: ${props => props.background == 'light' ? props.theme.colors.dark : props.theme.colors.white};
	}
	
	* {
		box-sizing: border-box;
		
		&:focus {
			outline: none;
		}
	}

	main {
		width: 100%;
		height: 100%;
	}

	button,
	input {
		border: 0;
		background: transparent;
		margin: 0;
		padding: 0;
		outline: 0;
		-webkit-font-smoothing: antialiased;
	}
	
	input,
	textarea,
	button,
	select,
	div,
	a {
		-webkit-tap-highlight-color: transparent;
	}

	h1 {
		font-family: MinionSemibold;
		font-size: 4rem;
		line-height: 4.5rem;

		${props => props.theme.above.desktop`
			font-size: 5.5rem;
			line-height: 6rem;
		`}
	}

	h2 {
		font-family: MinionSemibold;
		font-size: 2.5rem;
		line-height: 3rem;

		${props => props.theme.above.desktop`
			font-size: 5.5rem;	
			line-height: 6rem;
		`}
	}

	h3 {
		font-family: MinionSemibold;
		font-size: 4.5rem;
		line-height: 5.5rem;
	}

	h4 {
		font-family: MinionSemibold;
		font-size: 3rem;
		line-height: 3.5rem;
	}

	h5 {
		font-family: CircularMedium;
		font-size: 1.6rem;
		line-height: 2rem;
	}

	p, span {
		font-family: NeueMontrealRegular;
		font-size: 1.4rem;
		line-height: 2.5rem;
		letter-spacing: 0.12rem;

		color: ${props => props.theme.colors.darkText};

		strong {
			font-family: NeueMontrealRegular;
			font-weight: 600;

			color: ${props => props.theme.colors.white};
		}

		${props => props.theme.above.desktop`
			font-size: 1.6rem;
		`}
	}

	a {
		font-family: CircularMedium;
		font-size: 1.4rem;
		line-height: 2.5rem;
		letter-spacing: 0.05rem;

		color: inherit;
		text-decoration: none;
	}

	.swiper-container {
		.swiper-pagination {
			bottom: 3rem !important;
		}

		.swiper-pagination-bullet {
			background-color: rgba(255, 255, 255, 0.85);

			transition: all 0.2s ${props => props.theme.transitions.cubic};

			&.swiper-pagination-bullet-active, 
			&:hover {
				background-color: ${props => props.theme.colors.white};
			}
		}
	}

	.gm-style {
		.gm-style-iw-c {
			padding: 0;

			max-width: 30rem !important;
			
			border-radius: 0;
			box-shadow: none;

			background-color: transparent;
		}

		.gm-style-iw-d {
			max-width: 30rem !important;
			overflow: unset !important;
		}

		.gm-style-iw-t {
			&:after {
				background: white;
				box-shadow: none;
				top: -3px;
			}
		}

		.gm-ui-hover-effect {
			top: 10px !important;
			right: 5px !important;

			width: 40px !important;
			height: 40px !important;
			
			opacity: 1;

			img {
				filter: invert(1);

				width: 20px !important;
				height: 20px !important;

				margin: 0;
			}
		}
	}

	.mobile {
		display: block;
	}

	.desktop {
		display: none;
	}

    .parent {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        pointer-events: none;
    }

	${props => props.theme.above.desktop`
		.mobile {
			display: none;
		}

		.desktop {
			display: block;
		}		
	`}
`

export default GlobalStyle
