import React from 'react'
import styled from 'styled-components'

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

const Wrapper = styled.div`
    position: relative;

    background-color: ${props => props.theme.colors.darkLight};

    .alice-carousel__dots {
        margin-top: -3rem;
        padding-bottom: 4rem;
    }

    ${props => props.theme.above.desktop`
        padding: 10rem 0;

        .alice-carousel__dots {
            margin: 3rem 0 0 0;
            padding: 0;
        }
    `}
`

const Item = styled.div`
    width: 100%;

    padding: 6rem 3rem;

    text-align: center;

    ${props => props.theme.above.desktop`
        padding: 0;
    `}
`

const Text = styled.h3`
    width: 100%;
    max-width: 70rem;

    margin: 0 auto 1rem auto;

    font-family: ${props => props.theme.fontFamily.minionSemibold};
    font-size: 2.4rem;
    line-height: 3rem;
    
    color: ${props => props.theme.colors.white};

    ${props => props.theme.above.desktop`
        font-size: 3.5rem;
        line-height: 4rem;
    `}
`

const Author = styled.p`
    color: ${props => props.theme.colors.darkText};
`

const Quotes = ({
    data,
    className
}) => {
    return (
        <Wrapper className={className}>
            {data.length > 1 ? 
                <AliceCarousel
                    buttonsDisabled={true}
                    dotsDisabled={data.length > 1 ? false : true}
                    duration={500}
                    autoPlayInterval={5000}
                    autoPlay={true}
                    fadeOutAnimation={true}
                    responsive={{
                        0: {
                            items: 1
                        }
                    }}
                >
                    {data.map(({ quote }, index) =>
                        <Item key={index}>
                            <Text>{quote.document.data.quote.text}</Text>
                            <Author>{quote.document.data.author}</Author>
                        </Item>
                    )}
                </AliceCarousel>   
            :
                <Item>
                    <Text>{data[0].quote.document.data.quote.text}</Text>
                    <Author>{data[0].quote.document.data.author}</Author>
                </Item>
            }
        </Wrapper>
    )
}

export default Quotes
