import React, { useRef, useEffect } from "react"
import styled from "styled-components"

// const TitleWrapper = styled.div`
//     position: absolute;

//     z-index: 2;

//     writing-mode: tb-rl;

//     display: flex;
//     align-items: flex-start;
//     justify-content: flex-end;

//     top: 0;
//     left: 0;

//     height: 100%;

//     padding: 4rem 3rem;

//     ${props => props.theme.below.desktop`
//         display: none;
//     `}
// `
// const Title = styled.h5`
//     transform: rotate(180deg);
//     text-align: left;
//     font-family: ${props => props.theme.fontFamily.neueMontrealRegular};
//     font-size: 1.1rem;
// `

const TitleWrapper = styled.div`
    position: absolute;

    z-index: 2;

    bottom: 0;
    left: 0;

    width: 100%;

    padding: 4rem;

    ${props => props.theme.below.desktop`
        padding: 2rem;
    `}
`
const Title = styled.h5`
    text-align: left;
    /* font-family: ${props => props.theme.fontFamily.neueMontrealRegular}; */
    font-size: 1.25rem;

    ${props => props.theme.below.desktop`
        font-size: 1rem;

        text-align: center;
    `}
`

const VerticalTitle = ({ title, smaller }) => {
    
    const wrapperRef = useRef(null)

    if (!title) return null

    return (
        <TitleWrapper ref={wrapperRef} smaller={smaller}>
            <Title smaller={smaller}>{title}</Title>
        </TitleWrapper>
    )
}

export default VerticalTitle
