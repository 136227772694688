import React from 'react'
import Image from 'gatsby-image'
import styled from 'styled-components'

const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
`

const ResponsiveImage = ({
    className,
    mobile,
    desktop
}) => {
    return (
        <>
            {mobile && <StyledImage 
                className={className, 'mobile'} 
                objectFit='cover' 
                fluid={mobile.fluid} 
                alt={mobile.alt} 
            />}
            {desktop && <StyledImage 
                className={className, 'desktop'} 
                objectFit='cover' 
                fluid={desktop.fluid} 
                alt={desktop.alt} 
            />}
        </>
    )
}

export default ResponsiveImage
