import React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

import ProductItem from "./../Collection/Item"
import AboutItem from "./../About/Item"
import Title from "./../Title"
import BlogItem from "./../Blog/Item"

const Wrapper = styled.section`
    padding: 3rem 0;

    ${props =>
        props.color == "dark"
            ? `
        background-color: ${props.theme.colors.dark};
    `
            : `
        background-color: ${props.theme.colors.darkMedium};
    `}

    ${props => props.theme.above.desktop`
        padding: 12rem 0;

        // .swiper-container {
        //     &:active {
        //         .item {
        //             transform: scale(0.95);
        //         }
        //     }
        // }
    `}
`

const StyledTitle = styled(Title)`
    display: flex;
    flex-direction: column-reverse;

    ${props => props.theme.below.desktop`
        text-align: center;

        .title {
            margin-bottom: 0;
            
            font-family: 'MinionSemibold';
            font-size: 3rem;
            line-height: 3.5rem;
        }

        .line {
            display: none;
        }
    `}

    ${props => props.theme.above.desktop`
        .line {
            display: none;
        }
    `}
`

const Carousel = styled(motion.div)`
    position: relative;
`

const Item = styled.div`
    transition: transform 0.25s ${props => props.theme.transitions.cubic};

    ${props =>
        props.type == "product" &&
        `
        max-width: 40rem;
    `}
`

const StyledProductItem = styled(ProductItem)`
    .image-wrapper {
        max-height: 56rem;
    }

    picture {
        img {
            opacity: 1 !important;
        }
    }
`

const StyledBlogItem = styled(BlogItem)``

const Related = ({ lang, type, data, color, title, subtitle, description }) => {
    const params = {
        centeredSlides: true,
        initialSlide: 1,
        loop: true,
        preloadImages: false,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: type == "product" ? 1.5 : 1.15,
                spaceBetween: type == "product" ? 30 : 15,
            },
            1023: {
                slidesPerView: type == "product" ? 4.5 : 2.5,
                spaceBetween: 30,
            },
            2000: {
                slidesPerView: type == "product" ? 4.5 : 3.5,
                spaceBetween: 90,
            },
        },
    }

    const [ref, inView] = useInView({
        triggerOnce: true,
    })

    return (
        <Wrapper color={color}>
            <StyledTitle
                title={title}
                subtitle={subtitle && subtitle}
                description={description && description}
                alignment="center"
            />
            <Carousel
                ref={ref}
                animate={inView ? "after" : "before"}
                initial={{
                    opacity: 0,
                    y: 100,
                }}
                variants={{
                    before: {
                        opacity: 0,
                        y: 100,
                    },
                    after: {
                        opacity: 1,
                        y: 0,
                    },
                }}
                transition={{
                    duration: 0.35,
                    delay: 0.35,
                    damping: 100,
                    stiffness: 100,
                    ease: "circOut",
                }}
            >
                <Swiper {...params}>
                    {data.slice(0, 5).map((item, index) => {
                        return (
                            <Item type={type} key={index} className="item">
                                {type == "about" && item.item.uid && (
                                    <AboutItem
                                        lang={lang}
                                        uid={item.item.uid}
                                        data={item.item.document.data}
                                    />
                                )}
                                {type == "product" &&
                                    item.product.document.uid && (
                                        <StyledProductItem
                                            lang={lang}
                                            uid={item.product.document.uid}
                                            data={item.product.document.data}
                                        />
                                    )}
                                {type == "blog" && item.item.uid && (
                                    <StyledBlogItem
                                        lang={lang}
                                        uid={item.item.uid}
                                        data={item.item.document.data}
                                        related
                                    />
                                )}
                            </Item>
                        )
                    })}
                </Swiper>
            </Carousel>
        </Wrapper>
    )
}

export default Related
