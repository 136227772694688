import React from "react"
import styled, { css, keyframes } from "styled-components"
import { Link } from "gatsby"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import CircleSVG from "./../../graphics/button-circle.svg"
import ArrowSVG from "./../../graphics/button-arrow.svg"
import FlatArrowSVG from "./../../graphics/flat-arrow.svg"
import CrossSVG from "./../../graphics/button-cross.svg"
import MenuSVG from "./../../graphics/button-menu.svg"
import PlaySVG from "./../../graphics/button-play.svg"
import CartSVG from "./../../graphics/button-cart.svg"

const AnimationToBottom = keyframes`
    0% {
        transform: translate3d(-50%, -250%, 0);
    }
    50% {
        transform: translate3d(-50%, -50%, 0);
    }
    100% {
        transform: translate3d(-50%, 200%, 0);
    }
`

const AnimationToRight = keyframes`
    0% {
        transform: translate3d(-350%, -50%, 0) rotate(-90deg);
    }
    50% {
        transform: translate3d(-50%, -50%, 0) rotate(-90deg);
    }
    100% {
        transform: translate3d(300%, -50%, 0) rotate(-90deg);
    }
`

const ButtonStyles = css`
    display: block;

    cursor: pointer;

    color: currentColor;

    overflow: hidden;

    text-decoration: none;

    ${props => props.theme.above.desktop`
        &:hover {
            .top {
                stroke-dashoffset: 0;
            }
        }

        ${props.type == "arrow" &&
            props.direction == "down" &&
            css`
                &:hover {
                    .icon {
                        animation: ${AnimationToBottom} 1.5s forwards infinite;
                    }
                }
            `}

        ${props.type == "arrow" &&
            props.direction == "right" &&
            css`
                &:hover {
                    .icon {
                        animation: ${AnimationToRight} 1.5s forwards infinite;
                    }
                }
            `}
    `}
`

const Wrapper = styled.div`
    overflow: hidden;
`

const StyledInternal = styled(Link)`
    ${ButtonStyles}
`

const StyledButton = styled.button`
    ${ButtonStyles}
`

const StyledExternal = styled.a`
    ${ButtonStyles}
`

const Circle = styled(motion.div)`
    display: inline-block;
    vertical-align: middle;

    width: 5rem;
    height: 5rem;

    position: relative;

    overflow: hidden;

    color: ${props =>
        props.open ? props.theme.colors.dark : props.theme.colors.white};

    pointer-events: none;
`

const CircleIcon = styled(CircleSVG)`
    width: 100%;
    height: 100%;

    pointer-events: none;

    circle {
        stroke: currentColor;
    }

    .top {
        transform: rotate(-90deg);
        transform-origin: center;

        stroke-dasharray: 227;
        stroke-dashoffset: 227;

        transition: all 1.5s ${props => props.theme.transitions.cubic};
    }
`

const IconStyles = css`
    position: absolute;

    top: 50%;
    left: 50%;

    pointer-events: none;
`

const StyledArrow = styled(ArrowSVG)`
    ${IconStyles}

    width: 1.2rem;
    height: 1.8rem;

    ${props =>
        props.direction == "top"
            ? `
        transform: translate(-50%, -50%) rotate(-180deg);
    `
            : props.direction == "left"
            ? `
        transform: translate(-50%, -50%) rotate(90deg);
    `
            : props.direction == "right"
            ? `
        transform: translate(-50%, -50%) rotate(-90deg);
    `
            : `
        transform: translate(-50%, -50%);
    `}
`

const StyledCross = styled(CrossSVG)`
    ${IconStyles}

    width: 1.2rem;
    height: 1.2rem;

    ${props =>
        props.type == "plus"
            ? `
        transform: translate(-50%, -50%) rotate(45deg);
    `
            : `
        transform: translate(-50%, -50%);
    `}
`

const StyledMenu = styled(MenuSVG)`
    ${IconStyles}

    width: 1.5rem;
    height: 1.2rem;

    transform: translate(-50%, -50%);

    overflow: visible;

    line {
        stroke: currentColor;

        transition: all 1s ${props => props.theme.transitions.cubic};
    }

    ${props =>
        props.open &&
        `
        .top {
            transform: translate(-3.5px, -3.5px) rotate(-45deg);
            transform-origin: center;
        }
        
        .center {
            opacity: 0;
        }
        
        .bottom {
            transform: translate(-3.5px, 3.5px) rotate(45deg);
            transform-origin: center;
        }
    `}
`

const StyledPlay = styled(PlaySVG)`
    ${IconStyles}

    width: 1.5rem;
    height: 1.9rem;

    left: calc(50% + 0.2rem);

    transform: translate(-50%, -50%);
`

const StyledFlatArrow = styled(FlatArrowSVG)`
    ${IconStyles}

    width: 1.3rem;
    height: 1.4rem;

    ${props =>
        props.direction == "top"
            ? `
        transform: translate(-50%, -50%) rotate(90deg);
    `
            : props.direction == "bottom"
            ? `
        left: calc(50% - 0.1rem);
        transform: translate(-50%, -50%) rotate(-90deg);
    `
            : props.direction == "right"
            ? `
        left: calc(50% + 0.1rem);
        transform: translate(-50%, -50%) rotate(180deg);
    `
            : `
        transform: translate(-50%, -50%);
    `}
`

const StyledCart = styled(CartSVG)`
    ${IconStyles}

    width: 1.8rem;
    height: 1.8rem;

    transform: translate(-50%, -50%);
`

const Label = styled(motion.span)`
    display: inline-block;
    vertical-align: middle;

    margin-left: 1.5rem;

    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 1.4rem;
    letter-spacing: 0.05rem;

    color: ${props => props.theme.colors.white};
`

const Inner = ({ className, label, type, direction, menuOpen, animation }) => {
    return (
        <>
            <Circle
                className={(className, "circle")}
                open={menuOpen}
                animate={type == "menu" ? "after" : animation}
                initial="before"
                variants={{
                    before: {
                        y: -100,
                    },
                    after: {
                        y: 0,
                    },
                }}
                transition={{
                    duration: 0.55,
                    damping: 100,
                    stiffness: 100,
                    ease: "circOut",
                }}
            >
                <CircleIcon className="circle-icon" />
                {type == "arrow" && (
                    <StyledArrow className="icon" direction={direction} />
                )}
                {type == "cross" && <StyledCross className="icon" />}
                {type == "plus" && <StyledCross className="icon" type="plus" />}
                {type == "menu" && (
                    <StyledMenu className="icon" open={menuOpen} />
                )}
                {type == "play" && <StyledPlay className="icon" />}
                {type == "cart" && <StyledCart className="icon" />}
                {type == "flat-arrow" && (
                    <StyledFlatArrow className="icon" direction={direction} />
                )}
            </Circle>
            {label && (
                <Label
                    className="label"
                    animate={animation}
                    initial="before"
                    variants={{
                        before: {
                            opacity: 0,
                            y: 25,
                            skewY: 5,
                        },
                        after: {
                            opacity: 1,
                            y: 0,
                            skewY: 0,
                        },
                    }}
                    transition={{
                        duration: 0.35,
                        delay: 0.2,
                        damping: 100,
                        stiffness: 100,
                        ease: "circOut",
                    }}
                >
                    {label}
                </Label>
            )}
        </>
    )
}

const ButtonCircle = ({
    to,
    href,
    onClick,
    className,
    label,
    type,
    direction,
    menuOpen,
    visible,
    animation,
}) => {
    if (typeof visible == "undefined") {
        visible = true
    }

    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.5,
    })

    animation = animation || (inView ? "after" : "before")

    return (
        <Wrapper
            ref={ref}
            className={`button-circle ${className && className}`}
        >
            {to && (
                <StyledInternal to={to} type={type} direction={direction}>
                    <Inner
                        label={label}
                        type={type}
                        direction={direction}
                        animation={animation}
                    />
                </StyledInternal>
            )}
            {onClick && (
                <StyledButton
                    onClick={onClick}
                    type={type}
                    direction={direction}
                >
                    <Inner
                        label={label}
                        type={type}
                        direction={direction}
                        menuOpen={menuOpen}
                        animation={animation}
                    />
                </StyledButton>
            )}
            {href && (
                <StyledExternal
                    href={href}
                    target="_blank"
                    type={type}
                    direction={direction}
                >
                    <Inner
                        label={label}
                        type={type}
                        direction={direction}
                        animation={animation}
                    />
                </StyledExternal>
            )}
        </Wrapper>
    )
}

export default ButtonCircle
