import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import { generatePath } from "./../../utils/helpers"

const StyledShortNavigation = styled(motion.ul)`
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
`

const Inner = styled(motion.div)``

const ListItem = styled.li`
    display: inline-block;
    vertical-align: middle;

    margin-right: 4rem;

    overflow: hidden;

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        .link {
            opacity: 0.5;
        }
    }
`

const StyledLink = styled(Link)`
    position: relative;

    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 1.4rem;
    letter-spacing: 0.05rem;

    opacity: 1;

    transition: opacity 1s ${props => props.theme.transitions.cubic};
`

const StyledExternal = styled.a`
    position: relative;

    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 1.4rem;
    letter-spacing: 0.05rem;

    opacity: 1;

    transition: opacity 1s ${props => props.theme.transitions.cubic};
`

const ShortNavigation = ({ data, lang, visible }) => {
    return (
        <StyledShortNavigation
            animate={visible ? "after" : "before"}
            initial="before"
            variants={{
                before: {
                    transition: {
                        staggerChildren: 0,
                    },
                },
                after: {
                    transition: {
                        staggerChildren: 0.15,
                    },
                },
            }}
        >
            {data.map(({ primary: { link, label } }, index) => {
                return (
                    <ListItem key={index}>
                        <Inner
                            variants={{
                                before: {
                                    y: -25,
                                    opacity: 0,
                                },
                                after: {
                                    y: 0,
                                    opacity: 1,
                                },
                            }}
                            transition={{
                                duration: 0.55,
                                damping: 100,
                                stiffness: 100,
                                ease: "circOut",
                            }}
                        >
                            {link.url ? (
                                <StyledExternal
                                    className="link"
                                    href={link.url}
                                    target="_blank"
                                >
                                    {label.text}
                                </StyledExternal>
                            ) : (
                                <StyledExternal
                                    className="link"
                                    href={
                                        link.url ||
                                        `https://www.livingcreations.com${generatePath(
                                            lang,
                                            link.uid
                                        )}`
                                    }
                                >
                                    {label.text}
                                </StyledExternal>
                                // <StyledLink
                                //     className="link"
                                //     activeClassName="active"
                                //     partiallyActive={
                                //         link.uid == "home" ? false : true
                                //     }
                                //     to={generatePath(
                                //         lang,
                                //         link.uid == "home" ? "" : link.uid
                                //     )}
                                // >
                                //     {label.text}
                                // </StyledLink>
                            )}
                        </Inner>
                    </ListItem>
                )
            })}
        </StyledShortNavigation>
    )
}

export default ShortNavigation
