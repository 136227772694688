import React, { useState, useRef } from "react"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"

import CircleSVG from "./../../graphics/button-circle.svg"
import FacebookSVG from "./../../graphics/facebook.svg"
import PinterestSVG from "./../../graphics/pinterest.svg"
import InstagramSVG from "./../../graphics/instagram.svg"

const Wrapper = styled.div`
    position: relative;

    cursor: pointer;

    color: ${props => props.theme.colors.white};

    &:hover {
        .bottom {
            opacity: 0.25;
            transform: scale(1);
        }
    }

    &:active {
        .top {
            stroke-dashoffset: 0;
        }
    }

    ${props =>
        props.type == "list" &&
        `
        margin-bottom: 3rem;

        .circle {
            display: none;
        }

        .item {
            display: block;

            width: auto;
            height: auto;

            margin-bottom: 1rem;
        }

        .icon {
            display: inline-block;
            vertical-align: middle;

            position: relative;

            top: initial;
            left: initial;

            transform: none;

            width: 3rem;
            height: 1.6rem;

            path {
                fill: ${props.theme.colors.black};
                stroke: none;
            }
        }

        .label {
            display: inline-block;
            vertical-align: middle;
        }

        ${props.theme.above.desktop`
            margin-bottom: 0;

            .item {
                margin-bottom: 0;
            }
        `}
    `}
`

const Circle = styled.div`
    position: absolute;

    top: 0;
    left: 0;

    width: 50px;
    height: 50px;

    overflow: hidden;

    transition: left 0.2s ${props => props.theme.transitions.cubic};
`

const CircleIcon = styled(CircleSVG)`
    circle {
        stroke: currentColor;
    }

    .bottom {
        opacity: 0;

        transform: scale(0.75);
        transform-origin: center;

        transition: all 0.35s ${props => props.theme.transitions.cubic};
    }

    .top {
        transform: rotate(-90deg);
        transform-origin: center;

        stroke-dasharray: 227;
        stroke-dashoffset: 227;

        transition: stroke-dashoffset 0.35s
            ${props => props.theme.transitions.cubic};
    }
`

const Item = styled(motion.li)`
    display: inline-block;
    vertical-align: middle;

    position: relative;

    width: 50px;
    height: 50px;

    transition: all 0.2s ${props => props.theme.transitions.cubic};

    &:hover {
        opacity: 1;
    }
`

const StyledLink = styled.a`
    display: block;
`

const Icon = styled.div`
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
`

const IconStyles = css`
    path {
        fill: currentColor;
        stroke: none;
    }
`

const StyledFacebookIcon = styled(FacebookSVG)`
    ${IconStyles}

    width: 9px;
    height: 16px;
`

const StyledInstagramIcon = styled(InstagramSVG)`
    ${IconStyles}

    width: 16px;
    height: 16px;
`

const StyledPinterestIcon = styled(PinterestSVG)`
    ${IconStyles}

    width: 13px;
    height: 15px;
`

const Label = styled.span`
    display: none;

    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 1.4rem;
    line-height: 2.5rem;
    letter-spacing: 0.05rem;

    color: currentColor;

    ${props => props.theme.above.desktop`
        font-size: 1.6rem;
        line-height: 3.5rem;
    `}
`

const Socials = ({ data, className, type, animation }) => {
    const wrapperEl = useRef(null)
    const [left, setLeft] = useState(0)

    const handleMouseEnter = e => {
        type != "list" &&
            setLeft(
                e.target.getBoundingClientRect().x -
                    wrapperEl.current.offsetLeft
            )
    }

    const wrapperVariants = {
        after: {
            transition: {
                delayChildren: 0.35,
                staggerChildren: 0.05,
            },
        },
        before: {
            transition: {
                staggerChildren: 0.5,
                staggerDirection: -1,
            },
        },
    }

    const itemVariants = {
        after: {
            opacity: 1,
            y: 0,
        },
        before: {
            opacity: 0,
            y: 15,
        },
    }

    return (
        <Wrapper type={type} className={className} ref={wrapperEl}>
            <Circle className="circle" style={{ left: `${left}px` }}>
                <CircleIcon />
            </Circle>
            <motion.ul
                animate={animation}
                initial="before"
                variants={wrapperVariants}
            >
                {data.map(({ link: { document } }, index) => {
                    return (
                        <Item
                            key={index}
                            className={`item ${document.data.title.text.toLowerCase()}`}
                            onMouseEnter={handleMouseEnter}
                            variants={itemVariants}
                            transition={{
                                duration: 0.35,
                                damping: 100,
                                stiffness: 100,
                                ease: "easeOut",
                            }}
                        >
                            <StyledLink
                                href={document.data.link.url}
                                target="_blank"
                            >
                                <Icon className="icon">
                                    {document.data.title.text == "Facebook" && (
                                        <StyledFacebookIcon />
                                    )}
                                    {document.data.title.text ==
                                        "Instagram" && <StyledInstagramIcon />}
                                    {document.data.title.text ==
                                        "Pinterest" && <StyledPinterestIcon />}
                                </Icon>
                                <Label className="label">
                                    {document.data.title.text}
                                </Label>
                            </StyledLink>
                        </Item>
                    )
                })}
            </motion.ul>
        </Wrapper>
    )
}

export default Socials
