import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Wrapper = styled.div`
    display: flex;
    width: auto;
    display: flex;
    gap: 1rem;
`

const Image = styled.img`
    width: 100%;
    max-width: 6rem;

    ${props => props.theme.below.desktop`
        
    `}
`

const Award = ({ awards, className }) => {


    const { allAwards } = useStaticQuery(graphql`
		query {
			allAwards: allFile(filter: {relativeDirectory: {eq: "awards"}}) {
                edges {
                    node {
                        name
                        publicURL
                        childImageSharp {
                            fluid(maxWidth: 50, quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
		}
    `)
    
    return (
        <Wrapper className={`award ${className}`}>
            
            {awards.map((item, index) => {
                const file = allAwards.edges.find((file) => { return file.node.name?.toLowerCase() === item.product_award?.toLowerCase() });

                if(file) {
                    return (
                        <Image key={index} src={file.node.publicURL} alt={`${item.product_award} award - Living Creations`} />
                    )
                } else {
                    return null;
                }


            })} 
        </Wrapper>
    )
}

export default Award
