import React from 'react'
import styled from 'styled-components'

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import LinkedImage from './../LinkedImage'

const StyledCarousel = styled.div`
    width: 100%;
    height: 100%;
`

const StyledLinkedImage = styled(LinkedImage)`
    width: 100%;
    height: 100%;
`

const Carousel = ({ 
    type,
    className,
    data,
    lang
}) => {

    const handleOnDragStart = e => {
        e.preventDefault()
    }
    
    return (
        <StyledCarousel className={className, type}>
            <AliceCarousel
                buttonsDisabled={true}
                dotsDisabled={true}
                duration={1000}
                autoPlayInterval={8000}
                autoPlay={true}
                disableAutoPlayOnAction={true}
                mouseTrackingEnabled
                stagePadding={{
                    paddingLeft: 120,
                    paddingRight: 120
                }}
                responsive={{
                    0: {
                        items: 1
                    }
                }}
            >
                {data.items.map((image, index) =>
                    <StyledLinkedImage
                        key={index}
                        lang={lang}
                        product={image.product_link && item.primary.product_link.document}
                        image={image.image}
                        onDragStart={handleOnDragStart}
                    />
                )}
            </AliceCarousel>
        </StyledCarousel>
    )
}

export default Carousel