// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-about-item-js": () => import("./../../../src/templates/AboutItem.js" /* webpackChunkName: "component---src-templates-about-item-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/AboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-classics-page-js": () => import("./../../../src/templates/ClassicsPage.js" /* webpackChunkName: "component---src-templates-classics-page-js" */),
  "component---src-templates-collection-page-js": () => import("./../../../src/templates/CollectionPage.js" /* webpackChunkName: "component---src-templates-collection-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-history-page-js": () => import("./../../../src/templates/HistoryPage.js" /* webpackChunkName: "component---src-templates-history-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/IndexPage.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-inspiration-page-js": () => import("./../../../src/templates/InspirationPage.js" /* webpackChunkName: "component---src-templates-inspiration-page-js" */),
  "component---src-templates-manifest-page-js": () => import("./../../../src/templates/ManifestPage.js" /* webpackChunkName: "component---src-templates-manifest-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/NewsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-no-product-page-js": () => import("./../../../src/templates/NoProductPage.js" /* webpackChunkName: "component---src-templates-no-product-page-js" */),
  "component---src-templates-not-found-page-js": () => import("./../../../src/templates/NotFoundPage.js" /* webpackChunkName: "component---src-templates-not-found-page-js" */),
  "component---src-templates-product-item-js": () => import("./../../../src/templates/ProductItem.js" /* webpackChunkName: "component---src-templates-product-item-js" */),
  "component---src-templates-tour-page-js": () => import("./../../../src/templates/TourPage.js" /* webpackChunkName: "component---src-templates-tour-page-js" */),
  "component---src-templates-warranty-page-js": () => import("./../../../src/templates/WarrantyPage.js" /* webpackChunkName: "component---src-templates-warranty-page-js" */)
}

