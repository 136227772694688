import React from "react"
import styled, { css } from "styled-components"
import { useInView, InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { Link } from "gatsby"

import { Socials, Colors } from "../Shared"
import Form from "./../Form"
import MarkSvg from "./../../graphics/mark.svg"

import { generatePath } from "../../utils/helpers"
import LanguageSwitch from "../LanguageSwitch"

const StyledFooter = styled.footer`
    width: 100%;
    overflow: hidden;

    background-color: ${props => props.theme.colors.white};

    color: ${props => props.theme.colors.dark};
`

const Container = styled.div`
    width: 100%;
    max-width: ${props => props.theme.breakpoints.extraWide}px;

    margin: 0 auto;
`

const Header = styled(motion.div)`
    padding: 3rem;

    text-align: center;

    ${props => props.theme.above.desktop`
        display: flex;
        align-items: center;
        justify-content: flex-start;

        padding: 12rem 12rem 3rem 12rem;

        text-align: left;
    `}
`

const StyledMark = styled(MarkSvg)`
    width: 7.5rem;
    height: 7.5rem;

    margin-bottom: 2rem;

    path {
        fill: ${props => props.theme.colors.dark};
        stroke: ${props => props.theme.colors.dark};
    }

    ${props => props.theme.above.desktop`
        width: 12rem;
        height: 12rem;

        margin-bottom: 0;
        margin-right: 10rem;
    `}
`

const Title = styled.h2`
    font-family: ${props => props.theme.fontFamily.circularMedium};
    font-size: 1.5rem;
    line-height: 2.5rem;

    ${props => props.theme.above.desktop`
        width: 100%;

        max-width: 80rem;

        font-size: 3rem;
        line-height: 4rem;
    `}
`

const List = styled(motion.ul)`
    border-top: 1px solid #ebebeb;

    padding-top: 3rem;

    ${props => props.theme.above.desktop`        
        padding: 6rem 12rem 9rem 12rem;
        
        border-top: none;
    `}
`

const Item = styled(motion.li)`
    margin-bottom: 3rem;
    padding: 0 3rem;

    &.social {
        border-bottom: 1px solid #ebebeb;
    }

    ${props => props.theme.above.desktop`
        display: inline-block;
        vertical-align: top;

        width: 25%;

        margin-bottom: 3rem;    
        padding: 0 3rem 0 0;

        &.menu {
            width: 20%;
        }

        &.social {
            width: 27.5%;

            border-bottom: none;
        }

        &.newsletter {
            width: 27.5%;
        }
    `}
`

const Inner = styled.div`
    width: 100%;

    ${props => props.theme.above.desktop`
        max-width: 30rem;   
    `}
`

const ItemTitle = styled.h5`
    margin-bottom: 1rem;

    ${props => props.theme.above.desktop`
        margin-bottom: 3rem;
    `}
`

const CopyStyles = css`
    position: relative;

    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 0.05rem;

    ${props => props.theme.above.desktop`
        font-size: 1.6rem;
        line-height: 2.8rem;
    `}
`

const ItemSubtitle = styled.p`
    ${CopyStyles}

    margin-bottom: 2rem;
`

const StyledLink = styled(Link)`
    display: block;

    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 1.4rem;
    line-height: 3rem;
    letter-spacing: 0.05rem;

    transition: opacity 1s ${props => props.theme.transitions.cubic};

    &:hover {
        opacity: 0.25;
    }

    ${props => props.theme.above.desktop`
        font-size: 1.6rem;
        line-height: 4rem;
    `}
`

const Content = styled.div`
    a {
        ${CopyStyles}

        display: inline-block;

        ${props => props.theme.underline("currentColor", "0.1rem")};

        font-family: ${props => props.theme.fontFamily.circularBook};

        color: ${props => props.theme.colors.black};
    }

    p {
        ${CopyStyles}
    }
`

const StyledSocials = styled(Socials)`
    color: ${props => props.theme.colors.dark};
`

const Bottom = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 3rem;

    border-top: 1px solid #ebebeb;

    text-align: center;

    ${props => props.theme.above.desktop`
        flex-direction: row;
        align-items: flex-end;

        padding: 0 12rem 3rem 12rem;

        border-top: 0;

        text-align: left;
    `}
`

const FooterBottomStyles = css`
    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.05rem;

    color: ${props => props.theme.colors.black};

    opacity: 0.35;
`

const Copyright = styled.span`
    ${FooterBottomStyles}

    ${props => props.theme.below.desktop`
        margin-bottom: 2rem;
   `}
`

const Disclaimer = styled.span`
    ${FooterBottomStyles}

    ${props => props.theme.above.desktop`
        max-width: 45rem;

        text-align: right;
    `}
`

const ColorsWrapper = styled(motion.div)``

const StyledColors = styled(Colors)`
    .list {
        max-width: none;
    }

    .title {
        display: none;
    }

    .color {
        height: 1rem;

        margin: 0;
    }

    ${props => props.theme.above.desktop`
        padding: 0 12rem;

        .color {
            height: 1.5rem;
            
            margin: 0;
        }
    `}
`

const itemVariants = {
    before: {
        opacity: 0,
        y: 50,
    },
    after: {
        opacity: 1,
        y: 0,
    },
}

const transition = {
    duration: 1,
    damping: 100,
    stiffness: 100,
    ease: "circOut",
}

const Footer = ({
    lang,
    prismicColors,
    prismicTheme: {
        data: { animation, mark, sitename },
    },
    prismicFooter: {
        data: {
            address,
            content_title,
            newsletter,
            language_title,
            disclaimer,
            menu,
            social,
            social_links,
        },
    },
}) => {
    const { items: footerColors } = prismicColors.nodes[
        Math.floor(Math.random() * prismicColors.nodes.length)
    ]

    const [ref, inView] = useInView({
        triggerOnce: true,
    })

    return (
        <StyledFooter name="footer">
            <Container>
                <Header
                    animate={inView ? "after" : "before"}
                    initial="before"
                    variants={{
                        before: {
                            opacity: 0,
                        },
                        after: {
                            opacity: 1,
                        },
                    }}
                    transition={transition}
                >
                    {/* <StyledMark src={animation.url} alt='Living Creations Mark' /> */}
                    <StyledMark />
                    <Title>{content_title.text}</Title>
                </Header>
                <List
                    ref={ref}
                    animate={inView ? "after" : "before"}
                    initial="before"
                    variants={{
                        before: {},
                        after: {
                            opacity: 1,
                        },
                    }}
                >
                    <Item
                        variants={itemVariants}
                        transition={transition}
                        className="address"
                    >
                        <ItemTitle>{address[0].title.text}</ItemTitle>
                        <Content
                            dangerouslySetInnerHTML={{
                                __html: address[0].content.html,
                            }}
                        />
                    </Item>
                    <Item
                        variants={itemVariants}
                        transition={transition}
                        className="menu"
                    >
                        <ItemTitle>Menu</ItemTitle>
                        {menu.map(
                            (
                                { link: { uid }, menu_title: { text } },
                                index
                            ) => (
                                <StyledLink
                                    to={generatePath(
                                        lang,
                                        uid == "home" ? "" : uid
                                    )}
                                    key={index}
                                >
                                    {text}
                                </StyledLink>
                            )
                        )}
                    </Item>
                    <Item
                        variants={itemVariants}
                        transition={transition}
                        className="social"
                    >
                        <Inner>
                            <ItemTitle>{social[0].title.text}</ItemTitle>
                            <ItemSubtitle>
                                {social[0].subtitle.text}
                            </ItemSubtitle>
                            <StyledSocials type="list" data={social_links} />
                        </Inner>
                    </Item>
                    <Item
                        variants={itemVariants}
                        transition={transition}
                        className="newsletter"
                    >
                        <Inner>
                            <ItemTitle>{newsletter[0].title.text}</ItemTitle>
                            <ItemSubtitle>
                                {newsletter[0].subtitle.text}
                            </ItemSubtitle>
                            <Form />
                            <div style={{ marginTop: "3rem" }}>
                                <ItemTitle>{language_title}</ItemTitle>
                                <LanguageSwitch />
                            </div>
                        </Inner>
                    </Item>
                </List>
                <Bottom
                    animate={inView ? "after" : "before"}
                    initial="before"
                    variants={{
                        before: {
                            opacity: 0,
                        },
                        after: {
                            opacity: 1,
                        },
                    }}
                    transition={transition}
                >
                    <Copyright>
                        &copy; {sitename.text} {new Date().getFullYear()}. All
                        Rights Reserved.
                    </Copyright>
                    <Disclaimer>{disclaimer.text}</Disclaimer>
                </Bottom>
                <ColorsWrapper
                    animate={inView ? "after" : "before"}
                    initial="before"
                    variants={{
                        before: {
                            opacity: 0,
                        },
                        after: {
                            opacity: 1,
                        },
                    }}
                    transition={transition}
                >
                    <StyledColors data={footerColors} type="footer" />
                </ColorsWrapper>
            </Container>
        </StyledFooter>
    )
}

export default Footer
